import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  DiffOutlined,
  ExportOutlined,
  FilterOutlined,
  LineChartOutlined,
  PlaySquareOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Checkbox,
  Col,
  Divider,
  Input,
  Popover,
  Row,
  Space,
  Statistic,
  Table,
  Typography
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { LatLngExpression, LatLngTuple } from "leaflet";
import { isNil, isUndefined } from "lodash-es";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deviceColorsArray } from "../../constants/colors";
import { size } from "../../helpers/pageHelper";
import { warningColor } from "../../pages/ProjectsPage";
import {
  ProjectWithUserRights,
  useDownloadSerializedDataQuery,
  useGetParametersQuery,
  useGetProjectGpsQuery
} from "../../state/cargologRestApi";
import { unpackFile } from "../../state/compareGraphsSlice";
import {
  openFinishTransportModal,
  setTransportToFinish
} from "../../state/modalsSlice";
import { unpackDatxAsync } from "../../state/openDatxSlice";
import { setProjectName } from "../../state/openParxSlice";
import { setParamsUserProgress } from "../../state/paramsPageSlice";
import {
  closeProjectModal,
  openMoveDeviceModal,
  projectsState,
  setParameterId
} from "../../state/projectSlice";
import {
  NormalButton,
  NormalButtonSmall,
  PrimaryButton
} from "../Common/CommonButtons";
import DeviceMap, { DeviceData, GpsPosData } from "../DevicesPage/DeviceMap";
import {
  CheckProjectRightsAccess,
  CheckUserRightsAccess
} from "../MicroComponents/LicenseAccess";
import FinishTransportModal from "../Modals/FinishTransportModal";
import { FatIcon } from "../../icons";
import { UserSwitchOutlined, UserOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography;

interface IProps {
  project?: ProjectWithUserRights;
}

const RecordingsTab = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedProjectId } = useSelector(projectsState);
  const { project } = props;

  const { data: parameters } = useGetParametersQuery(
    selectedProjectId ? { projectId: selectedProjectId } : skipToken
  );

  const { data: positions } = useGetProjectGpsQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  const loading = !project || !parameters || !positions;

  /** Used for centering the map when there is only one device in the project */
  let center: LatLngTuple = [57.8563, 14.30782];
  /** Used for creating a map bounds that shows all the devices in the project*/
  let outerBounds: LatLngTuple[] = [];
  const gpsData = positions
    ? positions.map((gps) => {
        const dateTime = gps.gpsData?.[0].dateTime;
        const timezoneOffset = new Date().getTimezoneOffset();
        const lastDateTime = dayjs
          .utc(dateTime)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        const lastVelocity = gps.gpsData?.[0].velocity;
        const lastStatus = gps.gpsData?.[0].status ?? 100;

        const firstDate = gps.gpsData?.[gps.gpsData.length - 1].dateTime;
        const firstDateTime = dayjs
          .utc(firstDate)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");
        const firstLat = gps.gpsData?.[gps.gpsData.length - 1].lat;
        const firstLon = gps.gpsData?.[gps.gpsData.length - 1].lon;

        const lastLat = gps.gpsData?.[0].lat ?? 57.8563;
        const lastLon = gps.gpsData?.[0].lon ?? 14.30782;

        const mapOrigo: LatLngExpression = [lastLat, lastLon];

        outerBounds.push(mapOrigo);
        center = mapOrigo;

        const lastPos = {
          lastDateTime: lastDateTime,
          lastLat: lastLat,
          lastLon: lastLon,
          lastVelocity: lastVelocity ?? 0,
          lastStatus: lastStatus
        };

        /** Used for Polyline */
        const gpsPosData: GpsPosData[] = gps.gpsData
          ? gps.gpsData.map((data) => {
              // todo: should this include all gps positions or only the devices?
              outerBounds.push([data.lat, data.lon]);

              return {
                path: [data.lat, data.lon],
                velocity: data.velocity,
                dateTime: data.dateTime,
                status: data.status
              };
            })
          : [];

        const sortedGpsPos = gpsPosData.sort((a, b) =>
          a.dateTime.localeCompare(b.dateTime)
        );
        return {
          parameterId: gps.parameterId ?? "",
          firstPos: {
            firstDateTime,
            firstLat,
            firstLon
          },
          lastPos: lastPos,
          gpsPosData: sortedGpsPos
        };
      })
    : [];

  const colorsArray = [...deviceColorsArray];
  const getLineColor = () => {
    return { color: colorsArray.pop() ?? "#409c46" };
  };

  const selectableDevices = parameters
    ? [...new Set(parameters.map((data) => data.deviceName))]
    : [];
  const [filterDeviceNames, setFilterDeviceNames] = useState<string[]>([]);
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const deviceData: DeviceData[] = parameters
    ? parameters
        .map((param, index) => {
          const gps = gpsData?.find((gps) => gps.parameterId === param.id);
          const deviceAlarms = project?.alarms?.filter(
            (alarm) => alarm.parameterId === param.id
          );
          return {
            parameterId: param.id ?? "",
            fileName: param.fileName ?? "",
            firstPos: gps?.firstPos,
            lastPos: gps?.lastPos,
            recordingStart: param.firstStart,
            latestData: param.hasData ? param.lastData : "",
            lastUploader: param.lastUploader ?? "",
            lastUploadType: param.lastUploadType ?? "",
            created: param.created ?? "",
            creator: param.creator ?? "",
            serialNumber: param.deviceName ?? "",
            deviceDescription: param.deviceDescription ?? "",
            gpsPosData: gps?.gpsPosData ?? [],
            lineColor: !isUndefined(gps) ? getLineColor().color : "transparent",
            alarms: deviceAlarms?.length ?? 0,
            project: {
              id: param.projectId,
              name: ""
            },
            hasData: param.hasData,
            isActive: param.isActive,
            key: index.toString()
          };
        })
        .filter((data) => {
          return (
            (filterDeviceNames.length === 0 ||
              filterDeviceNames.find(
                (deviceName) => deviceName === data.serialNumber
              )) &&
            (filterStatuses.length === 0 ||
              (filterStatuses.includes("active") && data.isActive) ||
              (filterStatuses.includes("finished") && !data.isActive)) &&
            (!searchQuery ||
              searchQuery === "" ||
              data
                .serialNumber!.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              data
                .deviceDescription!.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              data.fileName.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
        .sort((a: any, b: any) =>
          b.latestData?.toLowerCase().localeCompare(a.latestData?.toLowerCase())
        )
    : [];

  const mapData: DeviceData[] = deviceData.filter(
    (data) => data.gpsPosData.length > 0
  );

  const deviceColumns: ColumnsType<DeviceData> = [
    Table.EXPAND_COLUMN,
    {
      title: t("SerialNumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      render: (serialNumber: string, deviceData) => (
        <Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            marginBottom: 0,
            borderLeft: "5px solid " + deviceData.lineColor,
            paddingLeft: size.m1
          }}
        >
          {serialNumber}
        </Paragraph>
      ),
      sorter: (a, b) =>
        a.serialNumber
          .toLowerCase()
          .localeCompare(b.serialNumber.toLowerCase(), "sv")
    },
    {
      title: t("RecordingStart"),
      dataIndex: "recordingStart",
      key: "recordingStart",
      render: (recordingStart: string) => {
        if (isNil(recordingStart)) {
          return <Text disabled>{t("NoDataUploaded")}</Text>;
        }
        return dayjs.utc(recordingStart).local().format("YYYY-MM-DD HH:mm");
      },
      sorter: (a, b) => {
        if (isNil(a.recordingStart)) {
          return -1;
        } else if (isNil(b.recordingStart)) {
          return 1;
        } else {
          return (
            dayjs(a.recordingStart).unix() - dayjs(b.recordingStart).unix()
          );
        }
      }
    },
    {
      title: t("LatestUpload"),
      dataIndex: "latestData",
      key: "latestData",
      render: (latestData: string, deviceData) => {
        if (latestData === "") {
          return <Text disabled>{t("NoDataUploaded")}</Text>;
        }
        return (
          <>
            {dayjs.utc(latestData).local().format("YYYY-MM-DD HH:mm")}
            {deviceData.lastUploadType === "internal_user" && (
              <UserOutlined style={{ marginLeft: size.s1 }} />
            )}
            {deviceData.lastUploadType === "external_user" && (
              <UserSwitchOutlined style={{ marginLeft: size.s1 }} />
            )}
            {deviceData.lastUploadType === "lte_device" && (
              <FatIcon style={{ marginLeft: size.s1 }} />
            )}
          </>
        );
      },
      sorter: (a, b) => {
        if (a.latestData === "") {
          return -1;
        } else if (b.latestData === "") {
          return 1;
        } else {
          return dayjs(a.latestData).unix() - dayjs(b.latestData).unix();
        }
      }
    },
    {
      title: t("Alarms"),
      dataIndex: "alarms",
      key: "alarms",
      render: (alarms: number) => (
        <Statistic
          style={{ textAlign: "start" }}
          value={alarms}
          prefix={alarms > 0 ? <WarningOutlined /> : <CheckCircleOutlined />}
          valueStyle={{ color: warningColor(alarms > 0), fontSize: 14 }}
        />
      ),
      sorter: (a, b) => (a.alarms ?? 0) - (b.alarms ?? 0)
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive: boolean) =>
        isActive ? (
          <Text style={{ paddingRight: size.s1, color: warningColor(false) }}>
            <PlaySquareOutlined /> {t("StatusActive")}
          </Text>
        ) : (
          <Text disabled style={{ paddingRight: size.s1 }}>
            <CheckSquareOutlined /> {t("StatusFinished")}
          </Text>
        ),
      sorter: (a, b) => (a.isActive ? 1 : 0) - (b.isActive ? 1 : 0)
    }
  ];

  /** Using custom component instead of Leaflets built in LayersControl in order to show green checkboxes.
   * Put it outside of the map to avoid issues when clicking the Popover.
   */
  const customLayersControl = () => (
    <Popover
      placement="bottom"
      trigger="click"
      overlayStyle={{ minWidth: 160, paddingRight: size.s1 }}
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode.parentNode as HTMLElement
      }
      content={
        <>
          <Row>
            <Checkbox
              checked={showMap}
              style={{ width: "100%" }}
              onClick={() => setShowMap(!showMap)}
            >
              {" "}
              {t("ShowMap")}
            </Checkbox>
          </Row>
          <Divider style={{ marginBlock: size.s1 }} />
          <Row>
            <Checkbox
              checked={showStartPos}
              style={{ width: "100%" }}
              onClick={() => setShowStartPos(!showStartPos)}
            >
              {t("StartPosition")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showAlarms}
              style={{ width: "100%" }}
              onClick={() => setShowAlarms(!showAlarms)}
            >
              {t("Alarms")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showDevices}
              style={{ width: "100%" }}
              onClick={() => setShowDevices(!showDevices)}
            >
              {t("Devices")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showPolylines}
              style={{ width: "100%" }}
              onClick={() => setShowPolylines(!showPolylines)}
            >
              {t("DeviceLines")}
            </Checkbox>
          </Row>
        </>
      }
    >
      <NormalButton
        icon={<FilterOutlined />}
        style={{
          marginBottom: size.m2
        }}
      >
        {t("Map")}
      </NormalButton>
    </Popover>
  );

  const [showStartPos, setShowStartPos] = useState<boolean>(true);
  const [showDevices, setShowDevices] = useState<boolean>(true);
  const [showAlarms, setShowAlarms] = useState<boolean>(true);
  const [showPolylines, setShowPolylines] = useState<boolean>(true);
  const [showMap, setShowMap] = useState<boolean>(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly React.Key[]>(
    []
  );

  interface IDatxToDownload {
    parameterId: string;
    fileName: string;
    page: string;
  }
  // Current file being downloaded
  const [datxToDownload, setDatxToDownload] = useState<IDatxToDownload>();

  // downloads the selected file from the server
  const { data: selectedDatxFile, isLoading: loadingDatX } =
    useDownloadSerializedDataQuery(
      datxToDownload?.parameterId
        ? { parameterId: datxToDownload.parameterId }
        : skipToken
    );

  const openOnlineFile = (
    parameterId: string,
    fileName: string,
    page: string
  ) => {
    setDatxToDownload({ parameterId, fileName, page });
  };

  useEffect(() => {
    if (!isUndefined(selectedDatxFile) && datxToDownload?.page === "/graph") {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({
          filePath: datxToDownload?.fileName ?? "Online file",
          rawData
        })
      );
      dispatch(closeProjectModal());
      navigate(datxToDownload.page);
      setDatxToDownload(undefined);
    }

    if (!isUndefined(selectedDatxFile) && datxToDownload?.page === "/compare") {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackFile({
          filePath: datxToDownload?.fileName ?? "Online file",
          rawData
        })
      );
      dispatch(closeProjectModal());
      navigate(datxToDownload.page);
      setDatxToDownload(undefined);
    }
  }, [selectedDatxFile]);

  const mayOpenFilesInReports = CheckUserRightsAccess("ACB");
  const mayOpenFilesInCompare = CheckUserRightsAccess("ACC");
  const mayOpenProjectFiles =
    project?.userRights && CheckProjectRightsAccess(project?.userRights, "ADJ");

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <PrimaryButton
            style={{ marginBottom: size.m2 }}
            onClick={() => {
              dispatch(setParamsUserProgress(1));
              dispatch(setProjectName(project?.title ?? ""));
              dispatch(closeProjectModal());
              navigate("/params");
            }}
          >
            <PlusCircleOutlined /> {t("NewRecording")}
          </PrimaryButton>
        </Col>
        <Col>
          <Space>
            {customLayersControl()}
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterDeviceNames(v as string[])}
                  style={{ flexDirection: "column" }}
                >
                  {selectableDevices.length > 0 &&
                    selectableDevices.map((deviceName) => (
                      <Checkbox
                        defaultChecked={true}
                        value={deviceName}
                        checked={
                          !isUndefined(deviceName) &&
                          !isUndefined(filterDeviceNames) &&
                          filterDeviceNames.includes(deviceName)
                        }
                        key={deviceName}
                      >
                        {deviceName}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterDeviceNames.length > 0}
              >
                {t("Device")}
              </NormalButton>
            </Popover>
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterStatuses(v as string[])}
                  style={{ flexDirection: "column" }}
                >
                  <Checkbox
                    defaultChecked={false}
                    value="active"
                    checked={
                      filterStatuses && filterStatuses.includes("active")
                        ? true
                        : false
                    }
                  >
                    {t("StatusActive")}
                  </Checkbox>
                  <Checkbox
                    defaultChecked={false}
                    value="finished"
                    checked={
                      filterStatuses && filterStatuses.includes("finished")
                        ? true
                        : false
                    }
                  >
                    {t("StatusFinished")}
                  </Checkbox>
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterStatuses.length > 0}
              >
                {t("Status")}
              </NormalButton>
            </Popover>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchQuery(e.target.value as string)}
              allowClear
              style={{ marginBottom: size.m2 }}
            />
          </Space>
        </Col>
      </Row>
      {showMap && (
        <DeviceMap
          center={center}
          outerBounds={outerBounds}
          deviceData={mapData}
          showStartPos={showStartPos}
          showAlarms={showAlarms}
          showDevices={showDevices}
          showPolylines={showPolylines}
        />
      )}

      <Table
        style={{ minHeight: 400 }}
        dataSource={deviceData}
        loading={loading}
        columns={deviceColumns}
        pagination={{ hideOnSinglePage: true }}
        rowKey={(data) => data.parameterId}
        expandable={{
          onExpandedRowsChange: (key) => setExpandedRowKeys(key),
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: (data) => (
            <>
              <Row align="top" gutter={size.l2}>
                <Col span={8}>
                  <Row>
                    <Text strong>{t("RecordingDescription")}</Text>
                  </Row>
                  <Row>
                    <Paragraph style={{ marginBottom: 0 }}>
                      {data.deviceDescription}
                    </Paragraph>
                  </Row>
                  {data.deviceDescription === "" && (
                    <Row>
                      <Paragraph style={{ marginBottom: 0 }}>
                        <i>{t("NoDescription")}</i>
                      </Paragraph>
                    </Row>
                  )}
                </Col>
                <Col
                  span={10}
                  style={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                    minHeight: 56
                  }}
                >
                  <Row>
                    <Text strong>{t("FileName")}</Text>
                  </Row>
                  {data.fileName !== "" && data.hasData && (
                    <>
                      <Row>
                        <Paragraph style={{ marginBottom: 0 }}>
                          {data.fileName}
                        </Paragraph>
                      </Row>

                      <Row style={{ paddingTop: size.m1 }}>
                        {mayOpenFilesInReports && mayOpenProjectFiles && (
                          <NormalButtonSmall
                            icon={<LineChartOutlined />}
                            style={{
                              marginRight: size.m1,
                              marginBottom: 6
                            }}
                            disabled={loadingDatX}
                            loading={
                              loadingDatX &&
                              datxToDownload?.parameterId ===
                                data.parameterId &&
                              datxToDownload?.page === "/graph"
                            }
                            onClick={() => {
                              openOnlineFile(
                                data.parameterId,
                                data.fileName!,
                                "/graph"
                              );
                            }}
                          >
                            {t("OpenInReports")}
                          </NormalButtonSmall>
                        )}
                        {mayOpenFilesInCompare && mayOpenProjectFiles && (
                          <NormalButtonSmall
                            icon={<DiffOutlined />}
                            style={{
                              marginRight: size.m1,
                              marginBottom: 6
                            }}
                            disabled={loadingDatX}
                            loading={
                              loadingDatX &&
                              datxToDownload?.parameterId ===
                                data.parameterId &&
                              datxToDownload?.page === "/compare"
                            }
                            onClick={() => {
                              openOnlineFile(
                                data.parameterId,
                                data.fileName!,
                                "/compare"
                              );
                            }}
                          >
                            {t("OpenInCompare")}
                          </NormalButtonSmall>
                        )}
                      </Row>
                    </>
                  )}
                  {(data.fileName === "" || !data.hasData) && (
                    <Row>
                      <Paragraph style={{ marginBottom: 0 }}>
                        <i>{t("NoDataUploaded")}</i>
                      </Paragraph>
                    </Row>
                  )}
                </Col>
                <Col span={6}>
                  <Row>
                    <Text strong>{t("RecordingActions")}</Text>
                  </Row>
                  <Row style={{ paddingTop: size.s1 }}>
                    {data.isActive &&
                      project?.userRights &&
                      CheckProjectRightsAccess(project?.userRights, "ADM") &&
                      data.parameterId.length > 0 &&
                      !isUndefined(data.project?.id) && (
                        <NormalButtonSmall
                          icon={<CheckSquareOutlined />}
                          style={{
                            marginRight: size.m1,
                            marginBottom: 6
                          }}
                          onClick={() => {
                            dispatch(
                              setTransportToFinish({
                                id: data.parameterId,
                                projectId: data.project!.id,
                                deviceName: data.serialNumber,
                                deviceDescription: data.deviceDescription,
                                isActive: false
                              })
                            );
                            dispatch(openFinishTransportModal());
                          }}
                        >
                          {t("FinishTransport")}
                        </NormalButtonSmall>
                      )}
                    {project?.userRights &&
                      CheckProjectRightsAccess(project?.userRights, "ADK") && (
                        <NormalButtonSmall
                          icon={<ExportOutlined />}
                          style={{
                            marginRight: size.m1,
                            marginBottom: 6
                          }}
                          onClick={() => {
                            dispatch(
                              setParameterId({
                                parameterId: data.parameterId,
                                deviceName: data.serialNumber,
                                deviceDescription: data.deviceDescription
                              })
                            );
                            dispatch(openMoveDeviceModal());
                          }}
                        >
                          {t("ChangeProject")}
                        </NormalButtonSmall>
                      )}
                  </Row>
                </Col>
                <Col
                  span={8}
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, 0.06)",
                    paddingTop: size.s1,
                    minHeight: 56
                  }}
                >
                  <Row>
                    <Text strong>{t("RecordingCreatedDetails")}</Text>
                  </Row>
                  <Row>
                    <Text>
                      {t("Created")}:{" "}
                      {data.created
                        ? dayjs
                            .utc(data.created)
                            .local()
                            .format("YYYY-MM-DD HH:mm")
                        : t("Unknown")}
                    </Text>
                  </Row>
                  <Row>
                    <Text>
                      {t("Creator")}:{" "}
                      {data.creator ? data.creator : t("Unknown")}
                    </Text>
                  </Row>
                </Col>
                <Col
                  span={10}
                  style={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                    borderTop: "1px solid rgba(0, 0, 0, 0.06)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                    paddingTop: size.s1,
                    minHeight: 56
                  }}
                >
                  <Row>
                    <Text strong>{t("LatestUploadDetails")}</Text>
                  </Row>
                  <Row>
                    {data.latestData ? (
                      <Text>
                        {t("Uploaded")}:{" "}
                        {dayjs
                          .utc(data.latestData)
                          .local()
                          .format("YYYY-MM-DD HH:mm")}
                      </Text>
                    ) : (
                      <Text>
                        {t("Uploaded")}: {t("Unknown")}
                      </Text>
                    )}
                  </Row>
                  {data.lastUploadType === "internal_user" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {data.lastUploader}{" "}
                        <UserOutlined style={{ marginLeft: size.s1 }} />
                      </Text>
                    </Row>
                  )}
                  {data.lastUploadType === "external_user" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {t("ExternalUser")}{" "}
                        <UserSwitchOutlined style={{ marginLeft: size.s1 }} />
                      </Text>
                    </Row>
                  )}
                  {data.lastUploadType === "lte_device" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {t("Device")}{" "}
                        <FatIcon style={{ marginLeft: size.s1 }} />
                      </Text>
                    </Row>
                  )}
                  {data.lastUploadType === "" && (
                    <Row>
                      <Text>
                        {t("LatestUploadBy")}: {t("Unknown")}
                      </Text>
                    </Row>
                  )}
                </Col>
              </Row>
            </>
          )
        }}
      />
      <FinishTransportModal />
    </>
  );
};

export default RecordingsTab;
