import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Row, Table, Card, Select, notification } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { size } from "../../helpers/pageHelper";
import { DangerButtonSmall, NormalButton } from "../Common/CommonButtons";
import {
  openAddInternalUserModal,
  openInviteExternalUserModal
} from "../../state/projectSlice";
import { ColumnsType } from "antd/lib/table";
import { CheckProjectRightsAccess } from "../MicroComponents/LicenseAccess";
import { getUser } from "../../state/sessionSlice";
import {
  ProjectNotificationForCreation,
  ProjectWithUserRights,
  useGetProjectInvitesQuery,
  useCreateProjectNotificationMutation,
  useGetProjectNotificationByIdQuery,
  useUpdateProjectNotificationMutation,
  useGetUsersQuery,
  useDeleteProjectNotificationMutation,
  useDeleteProjectUserMutation,
  useGetLicensesQuery,
  useRemoveActiveInviteMutation,
  useUpdateProjectInvitedNotificationMutation
} from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  ActivePeopleData,
  InvitedPeopleData,
  activePeopleData,
  invitedPeopleData
} from "./projectPeopleData";

const { Text, Title } = Typography;
const { Option } = Select;

export interface peopleTableData {
  key: number;
  userData: IUserData;
  companyName: string;
  licenseActive?: string[];
  licenseInvited?: string;
  notifications?: EmailNotifications;
  notificationInvited?: NotificationInvited;
  action: {
    disabled: boolean;
    withdrawInvite?: () => void;
    removeUser?: () => void;
  };
}

export interface EmailNotifications {
  notificationId?: string;
  userId: string;
  type: number;
}

export interface NotificationInvited {
  notificationType: number;
  projectInviteId: string;
}

export interface IUserData {
  name: string;
  email: string;
}

interface IProps {
  project?: ProjectWithUserRights;
}
const PeopleTab = (props: IProps): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { project } = props;

  const user = useSelector(getUser);

  const { data: companyUsers } = useGetUsersQuery();
  const createdBy = companyUsers?.find(
    (user) => user.userId === project?.createdByUserId
  );

  const { data: projectInvites } = useGetProjectInvitesQuery();
  const { data: projectEmailNotifications } =
    useGetProjectNotificationByIdQuery(project?.id ? project.id : skipToken);

  let invitedPeople: peopleTableData[] = [];
  let activePeople: peopleTableData[] = [];
  let userRights: string[] = [];

  const { data: allLicenses } = useGetLicensesQuery({});
  const [removeActiveInvite] = useRemoveActiveInviteMutation();
  const [deleteProjectUser] = useDeleteProjectUserMutation();
  const [deleteProjectNotification] = useDeleteProjectNotificationMutation();

  const handleRemoveActiveInvite = async (request: {
    projectInviteId: string;
  }) => {
    const result: any = await removeActiveInvite(request);
    if (result.error) {
      notification.error({
        message: t("FailedToWithdrawInvite"),
        description: result.error.data ?? ""
      });
    }
  };

  const handleDeleteProjectUser = async (
    projectUserId: string,
    notificationId?: string
  ) => {
    const result: any = await deleteProjectUser({ projectUserId });
    if (result.error) {
      notification.error({ message: t("FailedToRemoveUser"), style: {} });
    } else if (notificationId) {
      deleteProjectNotification({ id: notificationId });
    }
  };

  if (
    projectInvites &&
    project &&
    allLicenses &&
    user.userId &&
    project.userRights
  ) {
    const invitedPeopleProps: InvitedPeopleData = {
      projectInvites,
      project,
      allLicenses,
      loggedInUserId: user.userId,
      withdrawInvite: handleRemoveActiveInvite
    };

    const activePeopleProps: ActivePeopleData = {
      project,
      emailNotifications: projectEmailNotifications,
      removeUser: handleDeleteProjectUser
    };

    userRights = project.userRights;
    invitedPeople = invitedPeopleData(invitedPeopleProps);
    activePeople = activePeopleData(activePeopleProps);
  }

  const [createNotification] = useCreateProjectNotificationMutation();
  const [updateNotification] = useUpdateProjectNotificationMutation();
  const [updateInvitedNotificationType] =
    useUpdateProjectInvitedNotificationMutation();

  const changeInvitedNotificationType = (
    notificationType: number,
    projectInviteId: string
  ) => {
    if (isUndefined(projectInviteId)) return;

    const request = {
      projectInviteId,
      notificationType
    };

    updateInvitedNotificationType(request);
  };

  const changeNotification = (emailNotifications: EmailNotifications) => {
    if (!isUndefined(project)) {
      const request: ProjectNotificationForCreation = {
        projectId: project.id,
        userId: emailNotifications.userId,
        type: emailNotifications.type
      };

      // Update if notification already exists
      if (
        projectEmailNotifications?.find(
          (alert) => alert.userId === emailNotifications.userId
        ) &&
        emailNotifications.notificationId
      ) {
        updateNotification({
          id: emailNotifications.notificationId,
          type: emailNotifications.type
        });
      } else {
        createNotification(request);
      }
    }
  };

  const invitedPeopleColumns: ColumnsType<peopleTableData> = [
    {
      title: t("Name"),
      dataIndex: "userData",
      key: "userData",
      width: "25%",
      render: (data: IUserData) => (
        <>
          <Title level={5} style={{ marginBlock: 0 }}>
            {data.name}
          </Title>
          <Text type="secondary">{data.email}</Text>
        </>
      ),
      sorter: (a, b) =>
        a.userData.name
          .toLowerCase()
          .localeCompare(b.userData.name.toLowerCase(), "sv")
    },
    {
      title: t("License"),
      dataIndex: "licenseInvited",
      key: "licenseInvited",
      width: "20%",
      render: (text: string) => <Text>{text}</Text>,
      sorter: (a, b) =>
        (a.licenseInvited?.toLowerCase() ?? "").localeCompare(
          b.licenseInvited?.toLowerCase() ?? "",
          "sv"
        )
    },
    {
      title: t("Notifications"),
      dataIndex: "notificationInvited",
      key: "notificationInvited",
      width: "20%",
      render: (notificationInvited: NotificationInvited) => {
        const hasAccess = CheckProjectRightsAccess(userRights, "ADK");

        return (
          <Select
            size="small"
            variant="borderless"
            dropdownStyle={{ minWidth: 160 }}
            disabled={!hasAccess}
            defaultValue={notificationInvited.notificationType ?? 0}
            onChange={(value: number) => {
              changeInvitedNotificationType(
                value,
                notificationInvited.projectInviteId
              );
            }}
          >
            <Option value={0}>{t("None")}</Option>
            <Option value={1}>{t("OnData")}</Option>
            <Option value={2}>{t("OnAlarm")}</Option>
          </Select>
        );
      }
    },
    {
      title: t("tableActions"),
      dataIndex: "action",
      key: "action",
      width: "20%",
      render: (action: { disabled: boolean; withdrawInvite: () => void }) => (
        <>
          <DangerButtonSmall
            icon={<CloseOutlined />}
            onClick={action.withdrawInvite}
            disabled={action.disabled}
          >
            {t("WithdrawInvite")}
          </DangerButtonSmall>
        </>
      )
    }
  ];

  const activePeopleColumns: ColumnsType<peopleTableData> = [
    {
      title: t("Name"),
      dataIndex: "userData",
      key: "userData",
      width: "25%",
      render: (data: IUserData) => (
        <>
          <Title level={5} style={{ marginBlock: 0 }}>
            {data.name}
          </Title>
          <Text type="secondary">{data.email}</Text>
        </>
      ),
      sorter: (a, b) =>
        a.userData.name
          .toLowerCase()
          .localeCompare(b.userData.name.toLowerCase(), "sv")
    },
    {
      title: t("Company"),
      dataIndex: "companyName",
      key: "companyName",
      width: "25%",
      render: (companyName: string) => <Text>{companyName}</Text>,
      sorter: (a, b) =>
        a.companyName
          .toLowerCase()
          .localeCompare(b.companyName.toLowerCase(), "sv")
    },
    {
      title: t("Licenses"),
      dataIndex: "licenseActive",
      key: "licenseActive",
      width: "20%",
      render: (licenseNames: string[]) =>
        licenseNames &&
        licenseNames.map((license, index) => (
          <Row key={index}>
            <Text>{license}</Text>
          </Row>
        ))
    },
    {
      title: t("Notifications"),
      dataIndex: "notifications",
      key: "notifications",
      width: "25%",
      render: (emailNotification: EmailNotifications) => {
        const hasAccess =
          CheckProjectRightsAccess(userRights, "ADK") ||
          emailNotification.userId === user.userId;

        return (
          <Row
            key={emailNotification.type}
            justify="start"
            style={{ width: "100%" }}
          >
            <Select
              size="small"
              variant="borderless"
              defaultValue={emailNotification.type ? emailNotification.type : 0}
              style={{}}
              disabled={!hasAccess}
              dropdownStyle={{ minWidth: 160 }}
              onChange={(v) => {
                changeNotification({
                  notificationId: emailNotification.notificationId,
                  userId: emailNotification.userId,
                  type: v
                });
              }}
            >
              <Option value={0}>{t("None")}</Option>
              <Option value={1}>{t("OnData")}</Option>
              <Option value={2}>{t("OnAlarm")}</Option>
            </Select>
          </Row>
        );
      }
    },
    {
      title: t("Remove"),
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (action: { disabled: boolean; removeUser: () => void }) => (
        <>
          <Row justify="center" style={{ width: "100%" }}>
            <DangerButtonSmall
              icon={<CloseOutlined />}
              onClick={action.removeUser}
              disabled={action.disabled}
            ></DangerButtonSmall>
          </Row>
        </>
      )
    }
  ];

  return (
    <>
      <div>
        {CheckProjectRightsAccess(userRights, "ADK") &&
          user.companyId === project?.companyId && (
            <Row style={{ marginBottom: size.m2 }}>
              <NormalButton
                onClick={() => dispatch(openAddInternalUserModal())}
              >
                {t("AddInternalUser")}
              </NormalButton>
              <NormalButton
                style={{ marginLeft: size.m1 }}
                onClick={() => dispatch(openInviteExternalUserModal())}
              >
                {t("InviteExternalUser")}
              </NormalButton>
            </Row>
          )}
        {invitedPeople.length > 0 && (
          <>
            <Card
              style={{ marginBottom: 0, marginTop: 0 }}
              styles={{
                body: {
                  paddingTop: size.m1,
                  paddingBottom: 0,
                  paddingInline: 0
                }
              }}
            >
              <Title
                level={5}
                style={{
                  paddingLeft: size.m2,
                  marginTop: 0,
                  marginBottom: size.m1
                }}
              >
                {t("PendingInvitations")}
              </Title>
              <Table
                columns={invitedPeopleColumns}
                dataSource={invitedPeople}
                pagination={{ hideOnSinglePage: true, defaultPageSize: 10 }}
                style={{ marginBottom: 0, paddingBottom: 0 }}
              />
            </Card>
            {/* Ghost element */}
            <Row style={{ paddingBlock: size.m1 }} />
          </>
        )}
        <Card
          style={{ marginTop: 0 }}
          styles={{
            body: {
              paddingTop: size.m1,
              paddingBottom: 0,
              paddingInline: 0
            }
          }}
        >
          <Title
            level={5}
            style={{
              paddingLeft: size.m2,
              marginTop: 0,
              marginBottom: size.m1
            }}
          >
            {t("MembersInProject")}
          </Title>
          <Table
            columns={activePeopleColumns}
            dataSource={activePeople}
            style={{ marginBottom: 0, paddingBottom: 0 }}
            pagination={{ hideOnSinglePage: true, defaultPageSize: 10 }}
          />
        </Card>
        {createdBy && (
          <Text>
            {t("ProjectCreatedBy")}: {createdBy.firstName} {createdBy.lastName}
          </Text>
        )}
      </div>
    </>
  );
};

export default PeopleTab;
