import { TFunction } from "i18next";
import { Content } from "pdfmake/interfaces";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  packTable,
  packTitle,
  packTransportHeader
} from "../../helpers/pdf/contentPackers";
import { createDocumentDefinition } from "../../helpers/pdf/createDocumentDefinition";
import { parseQuickReportTable } from "../../helpers/pdf/parseQuickReport";
import { parseSvg } from "../../helpers/pdf/parseSvg";
import { parseHtmlTables } from "../../helpers/pdf/parseHtmlTables";
import { createPdf } from "../../helpers/pdf/pdfCreator";
import { getFullWidth } from "../../helpers/pdf/pdfHelpers";
import {
  DocumentProps,
  ItemHeaderData,
  TransportHeaderData,
  svgTypes
} from "../../helpers/pdf/pdfInterfaces";
import {
  componentHeight,
  componentWidth,
  defaultExportMarginBottom
} from "../../helpers/pdf/pdfConstants";

export const exportMixedAsPdf = (
  html: HTMLElement,
  documentProps: DocumentProps,
  itemHeaders: ItemHeaderData[],
  t: TFunction,
  transportHeader?: TransportHeaderData
) => {
  const content: Content = [];
  const liElements = html.getElementsByTagName("li");
  if (transportHeader) {
    content.push(packTransportHeader(t, transportHeader));
  }

  for (let i = 0; i < liElements.length; i++) {
    const html = liElements[i];
    const itemHeader = itemHeaders[i];
    if (itemHeader) {
      if (itemHeader.itemTitle === itemHeader.itemType) {
        content.push(packTitle(t(itemHeader.itemTitle)));
      } else {
        content.push(packTitle(itemHeader.itemTitle));
      }
      if (itemHeader.itemType === "QuickReport") {
        const quickReport = parseQuickReportTable(html);
        content.push(packTable(quickReport, itemHeader.itemType));
      } else if (svgTypes.includes(itemHeader.itemType)) {
        const svg = parseSvg(html);
        content.push({
          svg: svg,
          width: getFullWidth(documentProps),
          marginBottom: defaultExportMarginBottom,
          pageBreak: "after"
        });
      } else {
        const tables = parseHtmlTables(html);
        tables.forEach((table) => {
          content.push(packTable(table, "default"));
        });
      }
    }
  }

  const documentDefinition = createDocumentDefinition({
    content,
    t,
    documentProps,
    transportHeader
  });
  createPdf(documentDefinition, documentProps.fileName);
};

/** Props for the PdfExportComponent */
interface PdfExportMultipleProps {
  ComponentBody: React.FC<any>;
  itemHeaders: ItemHeaderData[];
  documentProps: DocumentProps;
  transportHeader?: TransportHeaderData;
  reportExportDone: () => void;
}

/** Component wraping multiple JSX Components  */
export const PdfExportMultiple = (props: PdfExportMultipleProps) => {
  const { t } = useTranslation();
  const {
    ComponentBody,
    itemHeaders,
    documentProps,
    transportHeader,
    reportExportDone
  } = props;

  const itemRef = useRef();

  useEffect(() => {
    if (itemRef.current) {
      exportMixedAsPdf(
        itemRef.current!,
        documentProps,
        itemHeaders,
        t,
        transportHeader
      );
      reportExportDone();
    }
  }, [reportExportDone]);

  return (
    <div ref={itemRef as any}>
      <ComponentBody />
    </div>
  );
};

export interface ExportableItem {
  ComponentBody: React.FC<any>;
  componentProps: any;
}

interface MultiPdfExportProps {
  exportableItems: ExportableItem[];
  itemHeaders: ItemHeaderData[];
  transportHeader?: TransportHeaderData;
  documentProps: DocumentProps;
  reportExportDone: () => void;
}

/**
 * Higher order component that can be used to export mutiple components in a4
 * size. If all the components can't fit in 1 page, the final PDF will be more
 * than 1 pages long. The export will start directly when this component is
 * finished rendering. It is therefore recomended to use the exportHook
 * to only render it when the user want to start exporting
 * @param props
 */
export const ExportMultipleComponent = (props: MultiPdfExportProps) => {
  const {
    exportableItems,
    itemHeaders,
    documentProps,
    transportHeader,
    reportExportDone
  } = props;
  return (
    <PdfExportMultiple
      ComponentBody={() => <WrapMultiple exportableItems={exportableItems} />}
      itemHeaders={itemHeaders}
      documentProps={documentProps}
      transportHeader={transportHeader}
      reportExportDone={reportExportDone}
    />
  );
};

interface WrapMultipleProps {
  exportableItems: ExportableItem[];
}

/**
 * Wrap multible exportable items into one component
 * @param props
 */
export const WrapMultiple = (props: WrapMultipleProps) => {
  const { exportableItems } = props;

  const ulStyle: React.CSSProperties = {
    width: componentWidth,
    listStyle: "none",
    margin: 0,
    padding: 0
  };

  const liStyle: React.CSSProperties = {
    width: componentWidth,
    height: componentHeight,
    maxWidth: "100%"
  };

  return (
    <ul style={ulStyle}>
      {exportableItems.map((props, index) => {
        const { ComponentBody, componentProps } = props;
        return (
          <li key={index} style={{ ...liStyle }}>
            <ComponentBody
              width={componentWidth}
              height={componentHeight}
              {...componentProps!}
            />
          </li>
        );
      })}
    </ul>
  );
};
