import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { has, isEmpty, isNil, isUndefined, last, memoize } from "lodash-es";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import { AccHistogramData } from "../components/GraphPage/AccHistogramGraph";
import { VMDashboardHeader } from "../components/GraphPage/DashboardHeader";
import { DvaCardViewModel } from "../components/GraphPage/DvaDashboardCard";
import {
  ExternalIO,
  ExternalIOGraphData
} from "../components/GraphPage/ExternalIOGraph";
import {
  ExternalTimersGraphData,
  ExternalTimer
} from "../components/GraphPage/ExternalTimersGraph";
import { getMinMaxTableData } from "../components/GraphPage/MinMaxDashboardCard";
import {
  ExternalWarnings,
  QuickReportData
} from "../components/GraphPage/QuickReportDashboardCard";
import {
  GenericTimestampValue,
  VMScoreValues
} from "../components/GraphPage/ScoreValues";
import {
  AngleGraphPoint,
  DeviceHealthItem,
  ExtraStatusSymbol,
  StatusGraphData
} from "../components/GraphPage/StatusGraph";
import { getTop10 } from "../components/GraphPage/TopAccDashboardCard";
import { displayErrorMessage } from "../components/MicroComponents/GeneralUserFeedback";
import { PrintableItem } from "../components/PrintExport/hocPrintables";
import {
  ItemHeaderData,
  TransportHeaderData
} from "../helpers/pdf/pdfInterfaces";
import { ExportableItem } from "../components/PrintExport/pdfMultiExport";
import {
  alarmStatusBitMasks,
  encryptionKey,
  gpsStatusBitMasks
} from "../constants/FAT100-prot-constants";
import { floatToLocalizedString } from "../helpers/dataExportHelper";
import {
  createVmGeneralRecordingInformationCard,
  dtDva2ViewModel,
  formatLatLong,
  num2DateTuple,
  unix2Date,
  VMDvaData
} from "../helpers/dataModelHelper";
import {
  createDatasetWithUniqueTimestamps,
  createFilteredDataset,
  DtChannel,
  DtValue,
  getMinMaxDataset,
  RecordingDataBlockFiltered,
  RecordingDataBlockUnique
} from "../helpers/datasetHelper";
import {
  createDateTupleWithOffset,
  createTzDate,
  createUtcOffsetStr,
  guessLocalTz
} from "../helpers/dateHelper";
import {
  accWarningChecker,
  angleWarningChecker,
  createDatxFiltersBasedOnData,
  createDefaultHistogramBins,
  createDefaultYAxisDomain,
  createPrimaryGraphData,
  getAccWithDuration,
  getClosestDataPointToIndex,
  getClosestScoreValuesIndex,
  getDefaultYAxisTickCount,
  getDomainWithData,
  getNextScoreValuesPositionIndex,
  getPriorScoreValuesPositionIndex,
  isAccFilterActive,
  pressureWarningChecker,
  rhWarningChecker,
  tempWarningChecker,
  yAxisDefaultNormalizedValues,
  ZoomDimension
} from "../helpers/graphHelper";
import {
  AvailableDashboardKey,
  getAddableLayouts,
  getAllAvaibleLayouts,
  getAllAvailablePrintableLayouts,
  LayoutId,
  PrintableLayoutId
} from "../helpers/layoutHelper";
import { vmRecordingParametersFromPartial } from "../helpers/paramsHelper";
import { DatxHeader } from "../helpers/parsers/parseDatxHeaderHelper";
import { ParsedDatxWithHeader } from "../helpers/parsers/parseDatxHelper";
import {
  getAccHistogramGraphExportableItem,
  getAngleTableExportableItem,
  getExportableDvaGraphsFromSelected,
  getExportableDvaHeadersFromSelected,
  getExtIOTableExportableItem,
  getExtTimersTableExportableItem,
  getGeneralRecordingInfoExportableItem,
  getMinMaxTableExportableItem,
  getPrimaryGraphExportableItem,
  getQuickReportExportableItem,
  getTopAccTableExportableItem
} from "../helpers/pdf/pdfExportHelper";
import {
  getAccHistogramGraphPrintableItem,
  getAngleTablePrintableItem,
  getDvaGraphsFromMarked,
  getExtIOTablePrintableItem,
  getExtTimersTablePrintableItem,
  getGeneralRecordingInfoPrintableItem,
  getMinMaxTablePrintableItem,
  getPrimaryGraphPrintableItem,
  getQuickReportPrintableItem,
  getTopAccTablePrintableItem
} from "../helpers/printHelper";
import { timezoneSelector } from "../helpers/timezoneSelector";
import { DataFilterChannels } from "../models/DataFilterChannels";
import { RunningStatusTypes } from "../models/DTTypes";
import {
  DT_Acc,
  DT_Angle,
  DT_Pressure,
  DT_Rh,
  DT_Temp
} from "../models/FAT100DataTypes";
import { GeneralSystemInfo } from "../models/ISystemInfo";
import { VMRecordingParameters } from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { Optional, ValueOf } from "../utils/utilTypes";
import { getUser } from "./sessionSlice";
import { AppThunk, StoreApi } from "./store";
import IExternalRhTempParams from "../models/RecordingParameters/IExternalRhTempParams";

export interface IOpenDatxFiles {
  activeFileId?: string;
  openFiles: OpenDatxFiles;
  openNewFileStatus: OpenNewFileStatus;
}

type OpenNewFileStatus =
  | "openFileStatusInactive"
  | "openFileStatusDownload"
  | "openFileStatusParsing"
  | "openFileStatusRendering";

type OpenDatxFiles = Record<string, IOpenDatx>;

/** Interface describing an open datx-file */
export interface IOpenDatx {
  id: string;
  filePath: string;
  timezone: string;
  filters: DataFilterStates;
  datxContent: IDatxContent;
  dataDomain?: StateZoomDomain["x"];
  activeDomain: StateZoomDomain["x"];

  /** Contains an array that should act like stack to keep prior zoom levels
   * when zooming in */
  zoomDomainStack: StateZoomDomain[];
  zoomDimension: ZoomDimension;
  yAxisDomain: YAxisDomain;
  yAxisTickCount: YAxisTickCount;
  //todo: remove this and save a local copy somewhere where i can easily be removed
  rawData: number[];
  activeScoreValueCursor?: "primaryGraph" | "dvaGraph"; // Optional<Pick<LayoutId, "">>
  scoreValuesCursorPos?: number;
  scoreValuesSideToggle?: boolean;
  scoreValuesCursorPosIndex?: number;
  scoreValuesCursorPosPrimaryGraph?: boolean;
  scoreValuesCursorPosDvaGraph?: boolean;
  //context lines y-position
  contextLines?: number[];

  // Detailed Vibration Analysis (DVA)
  /** array index of the currently active dva data block */
  activeDvaBlockKey?: number;
  markedDvaBlockKeys: number[];
  /** Holds zoom domain for a particular dva-block */
  dvaZoomDomain?: DvaZoomDomain;
  dvaZoomDimension: ZoomDimension;
  dvaActiveTablePage: number;
  dvaPaginationSize: number;
  dvaScoreValuesCursorPosIndex?: number;
  //context lines DVA y-position
  dvaContextLines?: number[];

  activeDashboard: AvailableDashboardKey;
  histogramBins: { bins: number[]; max: number };

  /** Order of dashboard cards content that will be printed */
  printLayoutIds: PrintableLayoutId[];

  /** Encrypted password and if file has been unlocked */
  password: string;
  isUnlocked: boolean;

  markedMapKeys: number[];
}

/** Interface describing datx-content that comes from FAT100 */
export interface IDatxContent {
  header: DatxHeader;
  systemInfo?: GeneralSystemInfo;
  recordingParameters: VMRecordingParameters;
  data: RecordingDataBlockUnique[];
  dvaData: VMDvaData[];
  /** When user presses the button on fat100 */
  btnPresses?: number[];
  startup?: number[];
}

/** Interface describing datx-content that comes from FAT100 but with filtered
 * data. Usefull when craeting graphs */
interface FilteredDatxContent extends Omit<IDatxContent, "data"> {
  data: RecordingDataBlockFiltered[];
}

/** States for buttons that toggle which data-channels to display */
export interface DataFilterStates {
  xAcc: AccDataFilter;
  yAcc: AccDataFilter;
  zAcc: AccDataFilter;
  temp: CommonDataFilter;
  rh: CommonDataFilter;
  pressureRaw: CommonDataFilter;
  pressureComp: CommonDataFilter;
  angle: CommonDataFilter;
  gps: GpsDataFilter;
  extInput: CommonDataFilter;
  extOutput: CommonDataFilter;
  extTimer: CommonDataFilter;
  extTemp: Record<string, CommonDataFilter>; // Dynamic external temp channels
  extRh: Record<string, CommonDataFilter>; // Dynamic external RH channels
}

export interface CommonDataFilter {
  /** Hide values that is not above/below the alarm limit */
  hideDataWithinAlarmLevel: boolean;
  dataToggle: DataToggleState;
}

export interface AccDataFilterStates {
  xAcc: Partial<AccDataFilter>;
  yAcc: Partial<AccDataFilter>;
  zAcc: Partial<AccDataFilter>;
}
export interface AccDataFilter {
  /** Hide values that is not above/below the alarm limit */
  hideDataWithinAlarmLevel: boolean;
  dataToggle: DataToggleState;
  /** Hide value that are not inside dynamic values */
  showDynamicFilter?: boolean;
  accMin?: number;
  accMax?: number;
  accDuration?: number;
}

export interface GpsDataFilter {
  dataToggle: DataToggleState;
  hideStatusData: boolean;
  hideSensorData: boolean;
  hideScheduleData: boolean;
}

export interface AccDataToggleState {
  /** wether the channel is visible right now */
  isActive: boolean;
  /** wether the channel has been used at all during the recording period */
  isUsed: boolean;
}

export interface DataToggleState {
  /** wether the channel is visible right now */
  isActive: boolean;
  /** wether the channel has been used at all during the recording period */
  isUsed: boolean;
}

/** Zoom representation in the store */
export type StateZoomDomain = {
  x: [number, number];
  y: [number, number];
};

export interface YAxisDomain {
  acc?: YAxisLowHigh;
  temp?: YAxisLowHigh;
  rh?: YAxisLowHigh;
  pressure?: YAxisLowHigh;
}

export type YAxisLowHigh = [number, number];

export interface YAxisTickCount {
  accTick: number;
  tempTick: number;
  rhTick: number;
  pressureTick: number;
}

/** Contains zoomDomain stack (x y) and the dva-block-index the zoom belongs to */
export interface DvaZoomDomain {
  blockKey: number;
  zoomDomainStack: StateZoomDomain[];
}

const initialState: IOpenDatxFiles = {
  openNewFileStatus: "openFileStatusInactive",
  openFiles: {}
};

// errorCodes: 0 = No error
//             1 = Failed to init LTE module
//             2 = Failed to connect to network
//             3 = Failed to reach dataserver (timeout)
//             4 = Transmission not completed (aborted)
//             8 = LTE antenna error
// noGps:      1 = Blocked by no GPS position (GPS Required active)
// coMCU:      1 = Failed to init CoMCU
//             2 = Communication problems towards CoMCU
// power:      1 = LTE Power On
//             2 = LTE Power Off
// trigger:    1 = LTE connect trigged by sensor
//             2 = LTE connect trigged by schedule
export interface ILteStatus {
  timestamp: number;
  status: number[];
}

// Bit 0-3: Error code
//   0000 No error
//   0001 Failed to init GNSS module
//   0010 Failed to get position (timeout)
//   1000 Antenna error
// Bit4: 1 = Failed to init CoMCU
// Bit5: 1 = Communication problems towards
// CoMCU
// Bit6: 1 = Failed to get GPS pos (error response)
// Bit 8: 1 = GNSS Power On
// Bit 9: 1 = GNSS Power Off
// Bit 10: 1 = GNSS position trigged by sensor
// Bit 11: 1 = GNSS position trigged by schedule
export interface IGpsStatus {
  timestamp: number;
  status: number[];
}

export interface IAlarmStatus {
  timestamp: number;
  Acceleration: number;
  Temperature: number;
  Rh: number;
  Angle: number;
  Pressure: number;
  GPS: number;
  LTE: number;
  Other: number;
  External: number;
}

export interface IParsedGPSData {
  key: number;
  rowType: "gpsPosition" | "gpsStatus";
  position?: {
    latitude: number;
    longitude: number;
    gpsTimestamp: number;
    statusCode: number;
    velocity?: number;
  };
  status?: number; // separated one per row
  alarm?: {
    alarmSensor: number;
    alarmTimestamp: number;
    alarmData: RecordingDataBlockUnique;
  };
  blockTimestamp: number;
}

export type LatLngTuple = [number, number];

// The inferred type is too large for the TypeScript language server to serialize, so we will skip typing here and use any instead.
export const slice: any = createSlice({
  name: "openDatx",
  initialState,
  reducers: {
    setIsDownloadingDatxFile: (state) => {
      state.openNewFileStatus = "openFileStatusDownload";
    },
    setIsParsingDatxFile: (state) => {
      state.openNewFileStatus = "openFileStatusParsing";
    },
    setIsRenderingDatxFile: (state) => {
      state.openNewFileStatus = "openFileStatusRendering";
    },
    setFailedOpeningDatxFile: (state) => {
      state.openNewFileStatus = "openFileStatusInactive";
    },

    setNewOpenDatx: (
      state,
      action: PayloadAction<{
        filePath: string;
        unpackedData: ParsedDatxWithHeader;
        rawData: number[];
        /** Will default to "Standard" if not supplied */
        dashboardKey?: AvailableDashboardKey;
      }>
    ) => {
      const { unpackedData, filePath, rawData } = action.payload;

      let password: string = "";
      if (unpackedData.header && "password" in unpackedData.header) {
        password = unpackedData.header.password;
      }
      // Check for default or unset password
      const isUnlocked = password === "Àx:Ï" || password === "";

      const id = uuid();

      const datxContent = unpackedDataToDatxContent(unpackedData);

      //todo: change default domain
      const dataDomain: StateZoomDomain["x"] = isEmpty(datxContent.data)
        ? [0, 6]
        : getDomainWithData(datxContent.data);

      const filters = createDatxFiltersBasedOnData(datxContent.data);

      const activeDomain = dataDomain;
      const zoomDomainStack: StateZoomDomain[] = [
        { x: activeDomain, y: yAxisDefaultNormalizedValues }
      ];
      const zoomDimension = "x";
      // const yAxisToggle =
      const yAxisDomain = createDefaultYAxisDomain(datxContent);
      const yAxisTickCount = getDefaultYAxisTickCount();
      const maximumAcc =
        Math.max(-(yAxisDomain.acc?.[0] ?? 0), yAxisDomain.acc?.[1] ?? 0) ?? 16;
      const histogramBins = createDefaultHistogramBins(maximumAcc);
      const dvaZoomDimension = "x";
      const markedDvaBlockKeys: number[] = [];
      const dvaActiveTablePage = 1;
      const dvaPaginationSize = 6;
      const markedMapKeys: number[] = [];

      const activeDashboard = action.payload.dashboardKey ?? "Standard";

      const printLayoutIds: PrintableLayoutId[] = getAllAvaibleLayouts(
        datxContent.data,
        datxContent.dvaData
      );

      // Default is local timezone
      const timezone = guessLocalTz();

      const newFile: IOpenDatx = {
        id,
        filePath,
        timezone,
        rawData,
        datxContent,
        dataDomain,
        activeDomain,
        zoomDomainStack,
        zoomDimension,
        yAxisDomain,
        yAxisTickCount,
        histogramBins,
        dvaZoomDimension,
        markedDvaBlockKeys,
        dvaActiveTablePage,
        dvaPaginationSize,
        filters,
        printLayoutIds,
        activeDashboard,
        password,
        isUnlocked,
        markedMapKeys
      };

      // return {
      //   openFiles: {...state.openFiles, [newFile.id]: newFile},
      //   activeFileId: newFile.id,
      //   openNewFileStatus: "openFileStatusInactive"
      // }

      state.openFiles[newFile.id] = newFile;

      //setting this file to active
      state.activeFileId = newFile.id;

      state.openNewFileStatus = "openFileStatusInactive";
    },
    /** Unlocks a file with fileId if it equals the set password */
    unlockDatxFile: (
      state,
      action: PayloadAction<{
        fileId: string;
        password: string;
        isImpersonating?: boolean;
      }>
    ) => {
      const { fileId, password, isImpersonating } = action.payload;
      const targetFile = state.openFiles[fileId];
      const filePassword: string = targetFile.password;
      const sudo = isImpersonating === true && password === "SUDO";

      let correctPassword = password.length === 4;
      for (let i = 0; i < password.length; i++) {
        correctPassword =
          correctPassword &&
          password.charCodeAt(i) ===
            (filePassword.charCodeAt(i) ^ encryptionKey[i]);
      }
      targetFile.isUnlocked = correctPassword || sudo;
    },
    setActiveDatxFile: (state, action: { type: string; payload: string }) => {
      //TODO: any cool checks?
      state.activeFileId = action.payload;
    },
    closeOpenDatxFile: (state, action: { type: string; payload: string }) => {
      delete state.openFiles[action.payload];
    },
    closeAllOpenDatxFiles: (state) => {
      state.openFiles = {};
    },

    toggleDatxFilter: (
      state,
      action: {
        type: string;
        payload: { id: string; target: DataFilterChannels };
      }
    ) => {
      const targetFile = state.openFiles[action.payload.id];

      switch (action.payload.target) {
        case "xAcc":
          targetFile.filters.xAcc.dataToggle.isActive =
            !targetFile.filters.xAcc.dataToggle.isActive;
          break;
        case "yAcc":
          targetFile.filters.yAcc.dataToggle.isActive =
            !targetFile.filters.yAcc.dataToggle.isActive;
          break;
        case "zAcc":
          targetFile.filters.zAcc.dataToggle.isActive =
            !targetFile.filters.zAcc.dataToggle.isActive;
          break;
        case "temp":
          targetFile.filters.temp.dataToggle.isActive =
            !targetFile.filters.temp.dataToggle.isActive;
          break;
        case "rh":
          targetFile.filters.rh.dataToggle.isActive =
            !targetFile.filters.rh.dataToggle.isActive;
          break;
        case "pressureRaw":
          targetFile.filters.pressureRaw.dataToggle.isActive =
            !targetFile.filters.pressureRaw.dataToggle.isActive;
          break;
        case "pressureComp":
          targetFile.filters.pressureComp.dataToggle.isActive =
            !targetFile.filters.pressureComp.dataToggle.isActive;
          break;
        case "angle":
          targetFile.filters.angle.dataToggle.isActive =
            !targetFile.filters.angle.dataToggle.isActive;
          break;
        case "extInput":
          targetFile.filters.extInput.dataToggle.isActive =
            !targetFile.filters.extInput.dataToggle.isActive;
          break;
        case "extOutput":
          targetFile.filters.extOutput.dataToggle.isActive =
            !targetFile.filters.extOutput.dataToggle.isActive;
          break;
        case "extTimer":
          targetFile.filters.extTimer.dataToggle.isActive =
            !targetFile.filters.extTimer.dataToggle.isActive;
          break;
        default:
          // If target is not a default channel, it is probably a dynamic channel, which can currently be of type temp or rh
          if (action.payload.target.includes("extTemp_")) {
            const target = action.payload.target.split("extTemp_")[1];
            targetFile.filters.extTemp[target].dataToggle.isActive =
              !targetFile.filters.extTemp[target].dataToggle.isActive;
          } else if (action.payload.target.includes("extRh_")) {
            const target = action.payload.target.split("extRh_")[1];
            targetFile.filters.extRh[target].dataToggle.isActive =
              !targetFile.filters.extRh[target].dataToggle.isActive;
          } else {
            console.log(" Hello from toggleDatxFilter in openDatxSlice");
          }
          break;
      }

      targetFile.scoreValuesCursorPosIndex = undefined;
    },

    setShowDynamicFilter: (
      state,
      action: PayloadAction<{ fileId: string; value: boolean | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];
      targetFile.filters.xAcc.showDynamicFilter = action.payload.value;
      targetFile.filters.yAcc.showDynamicFilter = action.payload.value;
      targetFile.filters.zAcc.showDynamicFilter = action.payload.value;
    },

    setDynamicAcc: (
      state,
      action: PayloadAction<{
        fileId: string;
        value: AccDataFilterStates | undefined;
      }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      //xAcc
      targetFile.filters.xAcc.accMin = changeIfNull(
        action.payload.value?.xAcc?.accMin
      );
      targetFile.filters.xAcc.accMax = changeIfNull(
        action.payload.value?.xAcc?.accMax
      );
      targetFile.filters.xAcc.accDuration = changeIfNull(
        action.payload.value?.xAcc?.accDuration
      );

      //yAcc
      targetFile.filters.yAcc.accMin = changeIfNull(
        action.payload.value?.yAcc?.accMin
      );
      targetFile.filters.yAcc.accMax = changeIfNull(
        action.payload.value?.yAcc?.accMax
      );
      targetFile.filters.yAcc.accDuration = changeIfNull(
        action.payload.value?.yAcc?.accDuration
      );

      //zAcc
      targetFile.filters.zAcc.accMin = changeIfNull(
        action.payload.value?.zAcc?.accMin
      );
      targetFile.filters.zAcc.accMax = changeIfNull(
        action.payload.value?.zAcc?.accMax
      );
      targetFile.filters.zAcc.accDuration = changeIfNull(
        action.payload.value?.zAcc?.accDuration
      );
    },

    setDynamicXAccMin: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.xAcc.accMin = changeIfNull(action.payload.value);
    },
    setDynamicXAccMax: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.xAcc.accMax = changeIfNull(action.payload.value);
    },
    setDynamicXAccDuration: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.xAcc.accDuration = changeIfNull(action.payload.value);
    },

    setDynamicYAccMin: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.yAcc.accMin = changeIfNull(action.payload.value);
    },
    setDynamicYAccMax: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.yAcc.accMax = changeIfNull(action.payload.value);
    },
    setDynamicYAccDuration: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.yAcc.accDuration = changeIfNull(action.payload.value);
    },

    setDynamicZAccMin: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.zAcc.accMin = changeIfNull(action.payload.value);
    },
    setDynamicZAccMax: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.zAcc.accMax = changeIfNull(action.payload.value);
    },
    setDynamicZAccDuration: (
      state,
      action: PayloadAction<{ fileId: string; value: number | undefined }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.zAcc.accDuration = changeIfNull(action.payload.value);
    },

    toggleHideAccDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.xAcc.hideDataWithinAlarmLevel =
        !targetFile.filters.xAcc.hideDataWithinAlarmLevel;

      targetFile.filters.yAcc.hideDataWithinAlarmLevel =
        !targetFile.filters.yAcc.hideDataWithinAlarmLevel;

      targetFile.filters.zAcc.hideDataWithinAlarmLevel =
        !targetFile.filters.zAcc.hideDataWithinAlarmLevel;
    },

    toggleHideXAccDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.id];

      targetFile.filters.xAcc.hideDataWithinAlarmLevel =
        !targetFile.filters.xAcc.hideDataWithinAlarmLevel;
    },
    toggleHideYAccDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.id];

      targetFile.filters.yAcc.hideDataWithinAlarmLevel =
        !targetFile.filters.yAcc.hideDataWithinAlarmLevel;
    },
    toggleHideZAccDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.id];

      targetFile.filters.zAcc.hideDataWithinAlarmLevel =
        !targetFile.filters.zAcc.hideDataWithinAlarmLevel;
    },
    toggleHideTempDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.temp.hideDataWithinAlarmLevel =
        !targetFile.filters.temp.hideDataWithinAlarmLevel;
    },
    toggleHideRhDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.rh.hideDataWithinAlarmLevel =
        !targetFile.filters.rh.hideDataWithinAlarmLevel;
    },
    toggleHidePressureRawDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.pressureRaw.hideDataWithinAlarmLevel =
        !targetFile.filters.pressureRaw.hideDataWithinAlarmLevel;
    },
    toggleHidePressureCompDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.pressureComp.hideDataWithinAlarmLevel =
        !targetFile.filters.pressureComp.hideDataWithinAlarmLevel;
    },
    toggleHideAngleDataWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.angle.hideDataWithinAlarmLevel =
        !targetFile.filters.angle.hideDataWithinAlarmLevel;
    },
    toggleHideGpsStatusData: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.gps.hideStatusData =
        !targetFile.filters.gps.hideStatusData;
    },
    toggleHideGpsSensorData: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.gps.hideSensorData =
        !targetFile.filters.gps.hideSensorData;
    },
    toggleHideGpsScheduleData: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.gps.hideScheduleData =
        !targetFile.filters.gps.hideScheduleData;
    },
    toggleHideExtSensorRhWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string; target: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.extRh[action.payload.target].hideDataWithinAlarmLevel =
        !targetFile.filters.extRh[action.payload.target]
          .hideDataWithinAlarmLevel;
    },
    toggleHideExtSensorTempWithinAlarmLevel: (
      state,
      action: PayloadAction<{ fileId: string; target: string }>
    ) => {
      const targetFile = state.openFiles[action.payload.fileId];

      targetFile.filters.extTemp[
        action.payload.target
      ].hideDataWithinAlarmLevel =
        !targetFile.filters.extTemp[action.payload.target]
          .hideDataWithinAlarmLevel;
    },

    setActiveDataDomain: (
      state,
      action: {
        type: string;
        payload: { id: string; newDomain: [number, number] };
      }
    ) => {
      const activeFile = state.openFiles[action.payload.id];

      activeFile.activeDomain = action.payload.newDomain;
      //Also set a new zoom to not be out of sync
      activeFile.zoomDomainStack = [
        { x: action.payload.newDomain, y: yAxisDefaultNormalizedValues }
      ];
    },

    resetActiveDataDomain: (
      state,
      action: {
        type: string;
        payload: { id: string };
      }
    ) => {
      const activeFile = state.openFiles[action.payload.id];

      const domainWithData = activeFile.dataDomain;

      if (isNil(domainWithData)) {
        return;
      }

      activeFile.activeDomain = domainWithData;
      //Also set a new zoom to not be out of sync
      activeFile.zoomDomainStack = [
        { x: domainWithData, y: yAxisDefaultNormalizedValues }
      ];
    },

    setZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
        newDomain: StateZoomDomain;
      }>
    ) => {
      const { fileId, newDomain } = action.payload;

      const activeFile = state.openFiles[fileId];

      activeFile.zoomDomainStack.push(newDomain);
    },
    switchZoomMode: (
      state,
      action: PayloadAction<{
        fileId: string;
      }>
    ) => {
      const { fileId } = action.payload;
      const activeFile = state.openFiles[fileId];

      activeFile.zoomDimension === "x"
        ? (activeFile.zoomDimension = "xy")
        : (activeFile.zoomDimension = "x");
    },
    redoZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
      }>
    ) => {
      const { fileId } = action.payload;
      const activeFile = state.openFiles[fileId];

      if (activeFile.zoomDomainStack.length > 1) {
        activeFile.zoomDomainStack.pop();
      }
    },
    resetZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
      }>
    ) => {
      const { fileId } = action.payload;
      const activeFile = state.openFiles[fileId];

      if (activeFile.activeDomain) {
        activeFile.zoomDomainStack = [
          {
            x: activeFile.activeDomain,
            y: yAxisDefaultNormalizedValues
          }
        ];
      } else {
        activeFile.zoomDomainStack = [];
      }
    },

    resetGraphAxisScaleDefault: (state) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      const activeData = activeFile.datxContent;
      const defaultYAxisDomain = createDefaultYAxisDomain(activeData);

      activeFile.yAxisDomain = defaultYAxisDomain;
    },
    setGraphAxisScaleAccMin: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.acc)) {
        return;
      }
      activeFile.yAxisDomain.acc[0] = action.payload;
      // Workaround for symmetric domain ie acc[0] = -acc[1]
      activeFile.yAxisDomain.acc[1] = -action.payload;
    },
    setGraphAxisScaleAccMax: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.acc)) {
        return;
      }

      activeFile.yAxisDomain.acc[1] = action.payload;
      // Workaround for symmetric domain ie acc[0] = -acc[1]
      activeFile.yAxisDomain.acc[0] = -action.payload;
    },
    setGraphAxisScaleTempMin: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.temp)) {
        return;
      }

      activeFile.yAxisDomain.temp[0] = action.payload;
    },
    setGraphAxisScaleTempMax: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.temp)) {
        return;
      }

      activeFile.yAxisDomain.temp[1] = action.payload;
    },
    setGraphAxisScaleRhMin: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.rh)) {
        return;
      }

      activeFile.yAxisDomain.rh[0] = action.payload;
    },
    setGraphAxisScaleRhMax: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.rh)) {
        return;
      }

      activeFile.yAxisDomain.rh[1] = action.payload;
    },
    setGraphAxisScalePressureMin: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.pressure)) {
        return;
      }

      activeFile.yAxisDomain.pressure[0] = action.payload;
    },
    setGraphAxisScalePressureMax: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      if (isNil(activeFile.yAxisDomain.pressure)) {
        return;
      }

      activeFile.yAxisDomain.pressure[1] = action.payload;
    },

    resetGraphAxisTickCountDefault: (state) => {
      if (isNil(state.activeFileId)) {
        return;
      }
      const activeFile = state.openFiles[state.activeFileId];
      activeFile.yAxisTickCount = getDefaultYAxisTickCount();
    },
    setGraphAxisTickCountAcc: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      activeFile.yAxisTickCount.accTick = action.payload;
    },
    setGraphAxisTickCountTemp: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      activeFile.yAxisTickCount.tempTick = action.payload;
    },
    setGraphAxisTickCountRh: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      activeFile.yAxisTickCount.rhTick = action.payload;
    },
    setGraphAxisTickCountPressure: (
      state,
      action: { type: string; payload: number }
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      activeFile.yAxisTickCount.pressureTick = action.payload;
    },

    // Used for changing which side of Primary Graph Score Values is shown
    toggleScoreValueSide: (state) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];
      // First time set toggle to false
      if (isUndefined(activeFile.scoreValuesSideToggle)) {
        activeFile.scoreValuesSideToggle = false;
      } else {
        activeFile.scoreValuesSideToggle = !activeFile.scoreValuesSideToggle;
      }
    },
    // Set new cursor position for Primary graph
    setNewCursorPosition: (state, action: PayloadAction<number>) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];
      activeFile.activeScoreValueCursor = "primaryGraph";

      activeFile.scoreValuesCursorPosPrimaryGraph = true;
      activeFile.scoreValuesCursorPos = action.payload;
      activeFile.scoreValuesCursorPosIndex = undefined;
    },
    removeCursorPosition: (state) => {
      if (isNil(state.activeFileId)) {
        return;
      }
      const activeFile = state.openFiles[state.activeFileId];
      activeFile.activeScoreValueCursor = "primaryGraph";

      activeFile.scoreValuesCursorPosPrimaryGraph = false;
      activeFile.scoreValuesCursorPos = undefined;
      activeFile.scoreValuesCursorPosIndex = undefined;
    },
    moveScoreValuesCursorToNextActiveDataPoint: (
      state,
      action: PayloadAction<{ activeData: RecordingDataBlockFiltered[] }>
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      const currentPos = activeFile.scoreValuesCursorPos;
      const currentPosIndex = activeFile.scoreValuesCursorPosIndex;

      const { activeData } = action.payload;

      if (!currentPos || activeData.length === 0) return;

      // No prior position
      if (isNil(currentPosIndex)) {
        const nearestIndex = getClosestScoreValuesIndex(currentPos, activeData);
        const nearestEntry = activeData[nearestIndex];

        // Make sure that a value to the right is picked if available
        const indexRightOfCursor =
          // nearest value is left of cusor
          nearestEntry.timestamp < currentPos
            ? getNextScoreValuesPositionIndex(activeData, nearestIndex)
            : nearestIndex;

        const entryRightOfCursor = activeData[indexRightOfCursor];

        activeFile.scoreValuesCursorPosIndex = indexRightOfCursor;
        activeFile.scoreValuesCursorPos = entryRightOfCursor.timestamp;
      }
      // cursor already has a known position
      else {
        const newIndex = getNextScoreValuesPositionIndex(
          activeData,
          currentPosIndex
        );
        const newValue = activeData[newIndex];

        activeFile.scoreValuesCursorPosIndex = newIndex;
        activeFile.scoreValuesCursorPos = newValue.timestamp;
      }
    },
    moveScoreValuesCursorToPriorActiveDataPoint: (
      state,
      action: PayloadAction<{ activeData: RecordingDataBlockFiltered[] }>
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      const currentPos = activeFile.scoreValuesCursorPos;
      const currentPosIndex = activeFile.scoreValuesCursorPosIndex;

      const { activeData } = action.payload;

      if (!currentPos || activeData.length === 0) return;

      // No prior position
      if (isNil(currentPosIndex)) {
        const nearestIndex = getClosestScoreValuesIndex(currentPos, activeData);
        const nearestEntry = activeData[nearestIndex];

        // Make sure that a value to the left is picked if available

        const indexLeftOfCursor =
          // nearest value is right of cusor
          nearestEntry.timestamp > currentPos
            ? getPriorScoreValuesPositionIndex(activeData, nearestIndex)
            : nearestIndex;

        const entryLeftOfCursor = activeData[indexLeftOfCursor];

        activeFile.scoreValuesCursorPosIndex = indexLeftOfCursor;
        activeFile.scoreValuesCursorPos = entryLeftOfCursor.timestamp;
      }
      // cursor already has a known position
      else {
        const priorIndex = getPriorScoreValuesPositionIndex(
          activeData,
          currentPosIndex
        );
        const priorValue = activeData[priorIndex];

        activeFile.scoreValuesCursorPosIndex = priorIndex;
        activeFile.scoreValuesCursorPos = priorValue.timestamp;
      }
    },

    setContextLineNewPos: (state, action: PayloadAction<number>) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];
      const { contextLines } = activeFile;

      activeFile.contextLines = contextLines?.concat(action.payload) ?? [
        action.payload
      ];
    },
    removeNearestContextLineIfClose: (state, action: PayloadAction<number>) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];
      const { contextLines: contextLinePos } = activeFile;

      const closeContextLines = contextLinePos?.filter(
        (x) => Math.abs(x - action.payload) < 0.05
      );

      if (!closeContextLines) {
        return;
      }

      if (closeContextLines.length > 1) {
        const toRemove = closeContextLines.reduce((prev, curr) =>
          Math.abs(curr - action.payload) < Math.abs(prev - action.payload)
            ? curr
            : prev
        );

        activeFile.contextLines = contextLinePos?.filter((x) => x !== toRemove);
        return;
      }

      activeFile.contextLines = contextLinePos?.filter(
        (x) => x !== closeContextLines[0]
      );
    },

    setActiveDvaBlock: (
      state,
      action: PayloadAction<{ fileId: string; blockKey: number }>
    ) => {
      const { fileId, blockKey } = action.payload;

      const activeFile = state.openFiles[fileId];

      if (blockKey !== activeFile.activeDvaBlockKey) {
        activeFile.dvaScoreValuesCursorPosIndex = undefined;
      }

      activeFile.activeDvaBlockKey = blockKey;

      //switch dva table pagination so that the active block will be visible
      activeFile.dvaActiveTablePage = Math.ceil(
        (blockKey + 1) / activeFile.dvaPaginationSize
      );
    },
    deactivateDvaBlock: (state, action: PayloadAction<{ fileId: string }>) => {
      const { fileId } = action.payload;

      const activeFile = state.openFiles[fileId];

      activeFile.dvaScoreValuesCursorPosIndex = undefined;

      activeFile.activeDvaBlockKey = undefined;
    },

    setMarkedDvaBlockIndexes: (
      state,
      action: PayloadAction<{ fileId: string; selectedIndexes: number[] }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      activeFile.markedDvaBlockKeys = action.payload.selectedIndexes;
    },
    toggleMarkAllDvaBlockIndexes: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      //all checkbox are marked
      if (
        activeFile.markedDvaBlockKeys.length ===
        activeFile.datxContent.dvaData.length
      ) {
        activeFile.markedDvaBlockKeys = [];
      }
      //Some or none checkboxes are marked
      else {
        //Mark all keys
        activeFile.markedDvaBlockKeys = activeFile.datxContent.dvaData.map(
          (item, index) => index
        );
      }
    },
    setDvaZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
        newDomain: StateZoomDomain;
      }>
    ) => {
      const { fileId, newDomain } = action.payload;

      const activeFile = state.openFiles[fileId];

      if (
        isNil(activeFile.dvaZoomDomain) ||
        activeFile.dvaZoomDomain.blockKey !== activeFile.activeDvaBlockKey
      ) {
        activeFile.dvaZoomDomain = {
          blockKey: activeFile.activeDvaBlockKey!,
          zoomDomainStack: []
        };
      }

      activeFile.dvaZoomDomain.zoomDomainStack.push(newDomain);
    },
    switchDvaZoomMode: (
      state,
      action: PayloadAction<{
        fileId: string;
      }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      activeFile.dvaZoomDimension === "x"
        ? (activeFile.dvaZoomDimension = "xy")
        : (activeFile.dvaZoomDimension = "x");
    },
    redoDvaZoomDomain: (
      state,
      action: PayloadAction<{
        fileId: string;
      }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      if (!isEmpty(activeFile.dvaZoomDomain?.zoomDomainStack)) {
        activeFile.dvaZoomDomain?.zoomDomainStack.pop();
      }
    },
    resetDvaZoomDomain: (state, action: PayloadAction<{ fileId: string }>) => {
      const activeFile = state.openFiles[action.payload.fileId];

      activeFile.dvaZoomDomain = undefined;
    },
    setDvaTableActivePage: (
      state,
      action: PayloadAction<{ fileId: string; page: number; pageSize?: number }>
    ) => {
      const { fileId, page, pageSize } = action.payload;

      const activeFile = state.openFiles[fileId];

      if (pageSize) {
        activeFile.dvaPaginationSize = pageSize;
      }

      activeFile.dvaActiveTablePage = page;
    },
    // Set new cursor position for DVA graph
    setNewDvaCursorPosition: (state, action: PayloadAction<number>) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];
      activeFile.activeScoreValueCursor = "dvaGraph";

      activeFile.scoreValuesCursorPosDvaGraph = true;
      activeFile.dvaScoreValuesCursorPosIndex = Math.round(action.payload);
    },
    removeDvaCursorPosition: (state) => {
      if (isNil(state.activeFileId)) {
        return;
      }
      const activeFile = state.openFiles[state.activeFileId];
      activeFile.activeScoreValueCursor = "dvaGraph";

      activeFile.scoreValuesCursorPosDvaGraph = false;
      activeFile.dvaScoreValuesCursorPosIndex = undefined;
    },

    moveDvaScoreValuesCursorToNextActiveDataPoint: (state) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      const currentPosIndex = activeFile.dvaScoreValuesCursorPosIndex;

      const { activeDvaBlockKey, datxContent } = activeFile;
      const { dvaData } = datxContent;

      const activeDvaBlock = dvaData?.[activeDvaBlockKey!];

      if (isNil(currentPosIndex) || isEmpty(activeDvaBlock)) return;

      // cursor already has a known position
      const nextIndex = currentPosIndex + 1;
      // move to next index if cursor has not reached end of data
      if (nextIndex < activeDvaBlock.data.length) {
        activeFile.dvaScoreValuesCursorPosIndex = nextIndex;
      }
    },
    moveDvaScoreValuesCursorToPriorActiveDataPoint: (state) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];

      const currentPosIndex = activeFile.dvaScoreValuesCursorPosIndex;

      const { activeDvaBlockKey, datxContent } = activeFile;
      const { dvaData } = datxContent;

      const activeDvaBlock = dvaData?.[activeDvaBlockKey!];

      if (!currentPosIndex || isEmpty(activeDvaBlock)) return;

      // cursor already has a known position
      const nextIndex = currentPosIndex - 1;
      // move to prior index if cursor has not reached start of data
      if (currentPosIndex > 0) {
        activeFile.dvaScoreValuesCursorPosIndex = nextIndex;
      }
    },
    setDvaContextLineNewPos: (state, action: PayloadAction<number>) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];
      const { dvaContextLines } = activeFile;

      activeFile.dvaContextLines = dvaContextLines?.concat(action.payload) ?? [
        action.payload
      ];
    },
    removeNearestDvaContextLineIfClose: (
      state,
      action: PayloadAction<number>
    ) => {
      if (isNil(state.activeFileId)) {
        return;
      }

      const activeFile = state.openFiles[state.activeFileId];
      const { dvaContextLines } = activeFile;

      const closeContextLines = dvaContextLines?.filter(
        (x) => Math.abs(x - action.payload) < 1.5
      );

      if (!closeContextLines) {
        return;
      }

      if (closeContextLines.length > 1) {
        const toRemove = closeContextLines.reduce((prev, curr) =>
          Math.abs(curr - action.payload) < Math.abs(prev - action.payload)
            ? curr
            : prev
        );

        activeFile.dvaContextLines = dvaContextLines?.filter(
          (x) => x !== toRemove
        );
        return;
      }

      activeFile.dvaContextLines = dvaContextLines?.filter(
        (x) => x !== closeContextLines[0]
      );
    },

    updatePrintLayout: (
      state,
      action: PayloadAction<{ fileId: string; newLayout: PrintableLayoutId[] }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      //some sort of sort later

      activeFile.printLayoutIds = action.payload.newLayout;
    },

    setDefaultHistogramBins: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      const { data } = activeFile.datxContent;

      if (isNil(data)) {
        return;
      }

      activeFile.histogramBins = createDefaultHistogramBins(16);
    },

    updateHistogramBins: (
      state,
      action: PayloadAction<{ fileId: string; bins: number[] }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      const { data, systemInfo } = activeFile.datxContent;
      if (isNil(data)) {
        return;
      }

      let accStdMaxG = 16;
      if (systemInfo && has(systemInfo, "accStdMaxG")) {
        accStdMaxG = systemInfo.accStdMaxG;
      }
      //If histogramBins dont exist yet, create them
      if (isNil(activeFile.histogramBins)) {
        activeFile.histogramBins = createDefaultHistogramBins(accStdMaxG);
      }

      activeFile.histogramBins.bins = action.payload.bins;
    },

    setDataTimezone: (
      state,
      action: PayloadAction<{ fileId: string; zone: string }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      activeFile.timezone = action.payload.zone;
    },
    changeOpenDatxActiveDashboard: (
      state,
      action: PayloadAction<{
        fileId: string;
        newDashboardKey: AvailableDashboardKey;
      }>
    ) => {
      const activeFile = state.openFiles[action.payload.fileId];

      activeFile.activeDashboard = action.payload.newDashboardKey;
    },

    setMarkedMapKeys: (
      state,
      action: PayloadAction<{ fileId: string; markedKeys: number[] }>
    ) => {
      const { fileId, markedKeys } = action.payload;
      state.openFiles[fileId].markedMapKeys = markedKeys;
    }
  }
});

/** If value is null it returns value as undefined instead */
const changeIfNull = (value: number | undefined) => {
  if (value === null) {
    return (value = undefined);
  } else {
    return value;
  }
};

// =========== Actions with side effects (thunks)===========

/**
 * Unpacks a datx in a background thread and dispatch to state when ready
 * @param filePath
 * @param rawData
 */
export const unpackDatxAsync =
  (arg: { filePath: string; rawData: number[] }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch(setIsParsingDatxFile());

    const { filePath, rawData } = arg;
    const lastDashboardLayoutKey = state.persistantState.dashboard.active;

    try {
      if (typeof window.Worker === "function") {
        const worker = new Worker(
          new URL("../worker/parser.worker.ts", import.meta.url),
          { type: "module" }
        );
        worker.postMessage(rawData);
        worker.onmessage = (response) => {
          const unpackedData: ParsedDatxWithHeader = response.data;
          dispatch(
            setNewOpenDatx({
              filePath,
              unpackedData,
              rawData,
              dashboardKey: lastDashboardLayoutKey
            })
          );
        };
      } else {
        notification.error({
          message:
            "In order to use this function, please use a newer version of your browser"
        });
        throw new Error("Web worker not supported");
      }
    } catch (e) {
      notification.error({ message: `Invalid datx-file: ${e}` });
      dispatch(setFailedOpeningDatxFile());
    }
  };

// move score values cursor

export const setScoreValuesCursorPosToNext =
  (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const id = state.openDatx.activeFileId!;

    const activeData = selectFilteredDataById(id)(state);

    if (isNil(activeData)) {
      return;
    }

    dispatch(moveScoreValuesCursorToNextActiveDataPoint({ activeData }));
  };

export const setScoreValuesCursorPosToPrior =
  (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const id = state.openDatx.activeFileId!;

    const activeData = selectFilteredDataById(id)(state);

    if (isNil(activeData)) {
      return;
    }

    dispatch(moveScoreValuesCursorToPriorActiveDataPoint({ activeData }));
  };

export const {
  setIsDownloadingDatxFile,
  setIsParsingDatxFile,
  setIsRenderingDatxFile,
  setFailedOpeningDatxFile,
  setNewOpenDatx,
  unlockDatxFile,

  setShowDynamicFilter,
  setDynamicAcc,
  setDynamicXAccMin,
  setDynamicXAccMax,
  setDynamicXAccDuration,
  setDynamicYAccMin,
  setDynamicYAccMax,
  setDynamicYAccDuration,
  setDynamicZAccMin,
  setDynamicZAccMax,
  setDynamicZAccDuration,

  toggleDatxFilter,
  toggleHideAccDataWithinAlarmLevel,
  toggleHideXAccDataWithinAlarmLevel,
  toggleHideYAccDataWithinAlarmLevel,
  toggleHideZAccDataWithinAlarmLevel,
  toggleHideTempDataWithinAlarmLevel,
  toggleHideRhDataWithinAlarmLevel,
  toggleHidePressureRawDataWithinAlarmLevel,
  toggleHidePressureCompDataWithinAlarmLevel,
  toggleHideAngleDataWithinAlarmLevel,
  toggleHideGpsStatusData,
  toggleHideGpsSensorData,
  toggleHideGpsScheduleData,
  toggleHideExtSensorRhWithinAlarmLevel,
  toggleHideExtSensorTempWithinAlarmLevel,
  setActiveDatxFile,
  setActiveDataDomain,
  resetActiveDataDomain,
  setZoomDomain,
  switchZoomMode,
  redoZoomDomain,
  resetZoomDomain,
  closeOpenDatxFile,
  closeAllOpenDatxFiles,
  resetGraphAxisScaleDefault,
  setGraphAxisScaleAccMin,
  setGraphAxisScaleAccMax,
  setGraphAxisScaleTempMin,
  setGraphAxisScaleTempMax,
  setGraphAxisScaleRhMin,
  setGraphAxisScaleRhMax,
  setGraphAxisScalePressureMin,
  setGraphAxisScalePressureMax,
  resetGraphAxisTickCountDefault,
  setGraphAxisTickCountAcc,
  setGraphAxisTickCountTemp,
  setGraphAxisTickCountRh,
  setGraphAxisTickCountPressure,
  toggleScoreValueSide,
  removeCursorPosition,
  setNewCursorPosition,
  moveScoreValuesCursorToNextActiveDataPoint,
  moveScoreValuesCursorToPriorActiveDataPoint,
  setContextLineNewPos,
  removeNearestContextLineIfClose,
  setActiveDvaBlock,
  deactivateDvaBlock,
  setMarkedDvaBlockIndexes,
  toggleMarkAllDvaBlockIndexes,
  setDvaZoomDomain,
  switchDvaZoomMode,
  redoDvaZoomDomain,
  resetDvaZoomDomain,
  setDvaTableActivePage,
  setNewDvaCursorPosition,
  removeDvaCursorPosition,
  moveDvaScoreValuesCursorToNextActiveDataPoint,
  moveDvaScoreValuesCursorToPriorActiveDataPoint,
  setDvaContextLineNewPos,
  removeNearestDvaContextLineIfClose,
  updatePrintLayout,
  setDefaultHistogramBins,
  updateHistogramBins,
  setDataTimezone,
  changeOpenDatxActiveDashboard,
  setMarkedMapKeys
} = slice.actions;

export default slice.reducer;

export const selectGpsData = memoize((id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);

  const datxData = activeFile?.datxContent.data;

  const gpsData = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.gps))
    .map((block) => {
      const gps = {
        ...block.gps!,
        timestamp: block.timestamp
      };
      return gps;
    });

  const gpsStatus = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.gpsStatus))
    .map((block) => {
      const status: IGpsStatus = {
        timestamp: block.timestamp,
        status: block.gpsStatus!
      };
      return status;
    });

  const dataLink = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.dataLink))
    .map((block) => ({
      timestamp: block.timestamp,
      ...block.dataLink!
    }));

  const getTimezone = (): string => {
    const globalTzToggle = state.persistantState.globalTimezoneToggle;
    const globalTz = state.persistantState.globalTimezoneState;
    if (globalTzToggle && globalTz) {
      return globalTz;
    }
    return activeFile.timezone;
  };

  return {
    datxData,
    gpsData,
    gpsStatus,
    dataLink,
    serialNumber: activeFile.datxContent.header?.fat100Serial,
    markedMapKeys: activeFile.markedMapKeys,
    timezone: getTimezone()
  };
});

export const selectProcessedGpsData = memoize(
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    const datxData = activeFile.datxContent.data;
    const gpsFilters = activeFile.filters.gps;

    const recParams = activeFile.datxContent.recordingParameters;

    const accAlarmParams = {
      x: {
        alarmLevel: {
          positive: recParams.AccParams.params.Xalarm,
          negative: -recParams.AccParams.params.Xalarm
        },
        alarmDuration: recParams.AccParams.params.Xms
      },
      y: {
        alarmLevel: {
          positive: recParams.AccParams.params.Yalarm,
          negative: -recParams.AccParams.params.Yalarm
        },
        alarmDuration: recParams.AccParams.params.Yms
      },
      z: {
        alarmLevel: {
          positive: recParams.AccParams.params.Zalarm,
          negative: -recParams.AccParams.params.Zalarm
        },
        alarmDuration: recParams.AccParams.params.Zms
      }
    };

    const angleAlarmParams = {
      x: {
        positive: recParams.AngleParams.params.xAlarmLevel,
        negative: -recParams.AngleParams.params.xAlarmLevel
      },
      y: {
        positive: recParams.AngleParams.params.yAlarmLevel,
        negative: -recParams.AngleParams.params.yAlarmLevel
      },
      z: {
        positive: recParams.AngleParams.params.zAlarmLevel,
        negative: -recParams.AngleParams.params.zAlarmLevel
      }
    };

    const tempAlarmParams = recParams.TempParams.params;
    const rhAlarmParams = recParams.RhParams.params;
    const pressureRawAlarmParams = recParams.PressureParams.params;

    const { hideStatusData, hideSensorData, hideScheduleData } = gpsFilters;

    const dataLink = datxData
      .filter((block) => !isUndefined(block.dataLink))
      .map((block) => ({
        timestamp: block.timestamp,
        ...block.dataLink!
      }));

    // All GPS and GPS status blocks in order.
    const sortedBlocks: RecordingDataBlockUnique[] = datxData
      .filter(
        (block) => block.gps || (block.gpsStatus && block.gpsStatus.length > 0)
      )
      .sort((a, b) => a.timestamp - b.timestamp);

    let outerBounds: LatLngTuple[] = [];
    // Expand GPS status blocks, fill out alarm blocks and give each data point a unique key
    let gpsData: IParsedGPSData[] = [];
    let positionsData: IParsedGPSData[] = []; //same as gpsData but only with successful GPS positions
    let key = 0;

    sortedBlocks.forEach((block) => {
      // GPS position block
      if (!isUndefined(block.gps)) {
        let alarmSensor = 0;
        let alarmTimestamp: number | undefined = undefined;
        let alarmData: RecordingDataBlockUnique | undefined = undefined;

        // Fill out outer bounds for map
        outerBounds.push([
          formatLatLong(block.gps.latitude),
          formatLatLong(block.gps.longitude)
        ]);

        // If GPS is triggered by sensor, get the triggering data
        if ((block.gps.gpsStatus & 1024) > 0) {
          // Find the dataLink closest to the GPS position timestamp
          const closestDataLink =
            dataLink && dataLink.length > 0
              ? dataLink.reduce((prev, curr) => {
                  return Math.abs(curr.timestamp - block.timestamp) <
                    Math.abs(prev.timestamp - block.timestamp)
                    ? curr
                    : prev;
                })
              : { alarmDt: 0, alarmTimestamp: 0, linkSource: "" };

          alarmTimestamp = closestDataLink.alarmTimestamp;
          alarmSensor = closestDataLink.alarmDt;

          // Find the data closest to the alarm timestamp
          switch (alarmSensor) {
            case DT_Acc:
              alarmData = datxData
                ? datxData.reduce((prev, curr) => {
                    const hasAcc = curr.xAcc || curr.yAcc || curr.zAcc;

                    if (hasAcc) {
                      const isCloserToAlarmTimestamp =
                        Math.abs(
                          curr.timestamp - closestDataLink.alarmTimestamp
                        ) <
                        Math.abs(
                          prev.timestamp - closestDataLink.alarmTimestamp
                        );

                      if (isCloserToAlarmTimestamp) {
                        const isXAccAlarm =
                          curr.xAcc &&
                          (curr.xAcc[0] >
                            accAlarmParams.x.alarmLevel.positive ||
                            curr.xAcc[0] <
                              accAlarmParams.x.alarmLevel.negative) &&
                          curr.xAcc[1] > accAlarmParams.x.alarmDuration;

                        const isYAccAlarm =
                          curr.yAcc &&
                          (curr.yAcc[0] >
                            accAlarmParams.y.alarmLevel.positive ||
                            curr.yAcc[0] <
                              accAlarmParams.y.alarmLevel.negative) &&
                          curr.yAcc[1] > accAlarmParams.y.alarmDuration;

                        const isZAccAlarm =
                          curr.zAcc &&
                          (curr.zAcc[0] >
                            accAlarmParams.z.alarmLevel.positive ||
                            curr.zAcc[0] <
                              accAlarmParams.z.alarmLevel.negative) &&
                          curr.zAcc[1] > accAlarmParams.z.alarmDuration;

                        if (isXAccAlarm || isYAccAlarm || isZAccAlarm) {
                          return curr;
                        } else {
                          return prev;
                        }
                      } else {
                        return prev;
                      }
                    } else {
                      return prev;
                    }
                  })
                : undefined;
              break;
            case DT_Temp:
              alarmData = datxData
                ? datxData.reduce((prev, curr) => {
                    const hasTemp = curr.temp;

                    if (hasTemp) {
                      const isCloserToAlarmTimestamp =
                        Math.abs(
                          curr.timestamp - closestDataLink.alarmTimestamp
                        ) <
                        Math.abs(
                          prev.timestamp - closestDataLink.alarmTimestamp
                        );

                      if (isCloserToAlarmTimestamp) {
                        const isTempAlarm =
                          curr.temp &&
                          (curr.temp > tempAlarmParams.highAlarm ||
                            curr.temp < tempAlarmParams.lowAlarm);

                        if (isTempAlarm) {
                          return curr;
                        } else {
                          return prev;
                        }
                      } else {
                        return prev;
                      }
                    } else {
                      return prev;
                    }
                  })
                : undefined;
              break;
            case DT_Rh:
              alarmData = datxData
                ? datxData.reduce((prev, curr) => {
                    const hasRh = curr.rh;

                    if (hasRh) {
                      const isCloserToAlarmTimestamp =
                        Math.abs(
                          curr.timestamp - closestDataLink.alarmTimestamp
                        ) <
                        Math.abs(
                          prev.timestamp - closestDataLink.alarmTimestamp
                        );

                      if (isCloserToAlarmTimestamp) {
                        const isRhAlarm =
                          curr.rh &&
                          (curr.rh > rhAlarmParams.highAlarm ||
                            curr.rh < rhAlarmParams.lowAlarm);

                        if (isRhAlarm) {
                          return curr;
                        } else {
                          return prev;
                        }
                      } else {
                        return prev;
                      }
                    } else {
                      return prev;
                    }
                  })
                : undefined;
              break;
            case DT_Angle:
              alarmData = datxData
                ? datxData.reduce((prev, curr) => {
                    const hasAngle = curr.angle;

                    if (hasAngle) {
                      const isCloserToAlarmTimestamp =
                        Math.abs(
                          curr.timestamp - closestDataLink.alarmTimestamp
                        ) <
                        Math.abs(
                          prev.timestamp - closestDataLink.alarmTimestamp
                        );

                      if (isCloserToAlarmTimestamp) {
                        const isXAngleAlarm =
                          curr.angle &&
                          (curr.angle[0] > angleAlarmParams.x.positive ||
                            curr.angle[0] < angleAlarmParams.x.negative);

                        const isYAngleAlarm =
                          curr.angle &&
                          (curr.angle[1] > angleAlarmParams.y.positive ||
                            curr.angle[1] < angleAlarmParams.y.negative);

                        const isZAngleAlarm =
                          curr.angle &&
                          (curr.angle[2] > angleAlarmParams.z.positive ||
                            curr.angle[2] < angleAlarmParams.z.negative);

                        if (isXAngleAlarm || isYAngleAlarm || isZAngleAlarm) {
                          return curr;
                        } else {
                          return prev;
                        }
                      } else {
                        return prev;
                      }
                    } else {
                      return prev;
                    }
                  })
                : undefined;
              break;
            case DT_Pressure:
              alarmData = datxData
                ? datxData.reduce((prev, curr) => {
                    // Use temperature compensated pressure for alarms
                    const hasPressure = curr.pressureComp;

                    if (hasPressure) {
                      const isCloserToAlarmTimestamp =
                        Math.abs(
                          curr.timestamp - closestDataLink.alarmTimestamp
                        ) <
                        Math.abs(
                          prev.timestamp - closestDataLink.alarmTimestamp
                        );

                      if (isCloserToAlarmTimestamp) {
                        const isPressureAlarm =
                          curr.pressureComp &&
                          (curr.pressureComp >
                            pressureRawAlarmParams.highAlarm ||
                            curr.pressureComp <
                              pressureRawAlarmParams.lowAlarm);

                        if (isPressureAlarm) {
                          return curr;
                        } else {
                          return prev;
                        }
                      } else {
                        return prev;
                      }
                    } else {
                      return prev;
                    }
                  })
                : undefined;
              break;
          }
        }

        const alarm =
          alarmTimestamp && alarmData
            ? {
                alarmSensor,
                alarmTimestamp: alarmData.timestamp ?? alarmTimestamp, // Use the timestamp of the alarm data if available, otherwise the dataLink timestamp
                alarmData
              }
            : undefined;

        const newPosition: IParsedGPSData = {
          key,
          rowType: "gpsPosition",
          position: {
            latitude: formatLatLong(block.gps.latitude),
            longitude: formatLatLong(block.gps.longitude),
            velocity: block.gps.velocity,
            statusCode: block.gps.gpsStatus,
            gpsTimestamp: block.gps.gpsTimestamp
          },
          alarm,
          blockTimestamp: block.timestamp
        };
        // Apply position filters
        const hideRow =
          (hideSensorData && alarm) || (hideScheduleData && !alarm);
        if (!hideRow) {
          gpsData.push(newPosition);
          positionsData.push(newPosition);
          key++;
        }
      }
      // GPS status block
      if (
        !hideStatusData &&
        !isUndefined(block.gpsStatus) &&
        block.gpsStatus.length > 0
      ) {
        for (let i = 0; i < block.gpsStatus.length; i++) {
          gpsData.push({
            key,
            rowType: "gpsStatus",
            status: block.gpsStatus[i],
            blockTimestamp: block.timestamp
          });
          key++;
        }
      }
    });

    const globalTzToggle = state.persistantState.globalTimezoneToggle;
    const globalTz = state.persistantState.globalTimezoneState;
    const timezone =
      globalTzToggle && globalTz ? globalTz : activeFile.timezone;

    // Find first and last position keys for drawing on map
    let firstPositionKey: number | undefined = undefined;
    let lastPositionKey: number | undefined = undefined;
    let i = 0;
    while (i < positionsData.length && isUndefined(firstPositionKey)) {
      if (positionsData[i].rowType === "gpsPosition") {
        firstPositionKey = positionsData[i].key;
      }
      i++;
    }
    i = positionsData.length - 1;
    while (i >= 0 && isUndefined(lastPositionKey)) {
      if (positionsData[i].rowType === "gpsPosition") {
        lastPositionKey = positionsData[i].key;
      }
      i--;
    }

    if (outerBounds.length === 1) {
      outerBounds.push(outerBounds[0]);
    }

    return {
      firstPositionKey,
      lastPositionKey,
      outerBounds,
      gpsData, // Parsed GPS data
      positionsData, // GPS data with positions
      serialNumber: activeFile.datxContent.header?.fat100Serial,
      markedMapKeys: activeFile.markedMapKeys,
      timezone
    };
  }
);

export const selectLteStatus = memoize((id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const timezone = selectTimezoneById(id)(state);
  const lteStatus = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.lteStatus))
    .map((block) => {
      const status: ILteStatus = {
        timestamp: block.timestamp,
        status: block.lteStatus!
      };
      return status;
    });
  return { lteStatus, timezone };
});

export const selectGpsStatus = memoize((id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const gpsStatus = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.gpsStatus))
    .map((block) => {
      const status: IGpsStatus = {
        timestamp: block.timestamp,
        status: block.gpsStatus!
      };
      return status;
    });
  return gpsStatus;
});

export const selectAlarmStatus = memoize((id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const alarmStatus = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.alarmStatus))
    .map((block) => {
      const alarms: IAlarmStatus = {
        timestamp: block.timestamp,
        Acceleration:
          (block.alarmStatus! & alarmStatusBitMasks.Acceleration) >> 0,
        Temperature:
          (block.alarmStatus! & alarmStatusBitMasks.Temperature) >> 2,
        Rh: (block.alarmStatus! & alarmStatusBitMasks.Rh) >> 4,
        Angle: (block.alarmStatus! & alarmStatusBitMasks.Angle) >> 6,
        Pressure: (block.alarmStatus! & alarmStatusBitMasks.Pressure) >> 8,
        GPS: (block.alarmStatus! & alarmStatusBitMasks.GPS) >> 10,
        LTE: (block.alarmStatus! & alarmStatusBitMasks.LTE) >> 12,
        Other: (block.alarmStatus! & alarmStatusBitMasks.Other) >> 14,
        External: (block.alarmStatus! & alarmStatusBitMasks.External) >> 16
      };
      return alarms;
    });
  return alarmStatus;
});

export const selectDataLink = memoize((id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const dataLink = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.dataLink))
    .map((block) => ({
      timestamp: block.timestamp,
      ...block.dataLink!
    }));
  return dataLink;
});

export const selectStatusLink = memoize((id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const statusLink = activeFile.datxContent.data
    .filter((block) => !isUndefined(block.statusLink))
    .map((block) => ({
      timestamp: block.timestamp,
      ...block.statusLink!
    }));
  return statusLink;
});

export const getShowDynamicFilter = (fileId: string) => (state: StoreApi) => {
  return state.openDatx.openFiles?.[fileId]?.filters.xAcc.showDynamicFilter;
};

// Input selectors. Can be composed with larger selectors for performance
// optimasations, or be used as is

/**
 * Select Datx content with the data already filtered according to the current
 * data filters
 * @param id
 */
const selectDatxFilteredContentById = (id: string) => {
  return createSelector(
    [selectDatxContentById(id), selectFilteredDataById(id)],
    (datxContent, filteredData): FilteredDatxContent => {
      return { ...datxContent, data: filteredData };
    }
  );
};

/** Select all datx data that is within the domain */
const selectDataInActiveDomain = memoize((id: string) => {
  return createSelector(
    [selectData(id), selectActiveDomainById(id)],
    (data, activeDomain) => {
      const [start, end] = activeDomain;

      return data.filter((item) => {
        return start <= item.timestamp && item.timestamp <= end;
      });
    }
  );
});

const selectDvaDataInActiveDomain = memoize((id: string) => {
  return createSelector(
    [selectDvaData(id), selectActiveDomainById(id)],
    (dvaData, activeDomain) => {
      const [start, end] = activeDomain;

      return dvaData.filter((item) => {
        //Note: item start since that is the best indicator for when the event took
        //place. Start and end ussualy has a diff of ~1sec
        return start <= item.start && item.start <= end;
      });
    }
  );
});

//todo move
const selectData = (id: string) => (state: StoreApi) => {
  return state.openDatx.openFiles?.[id]?.datxContent.data;
};

//todo move
const selectDvaData = (id: string) => (state: StoreApi) => {
  return state.openDatx.openFiles?.[id]?.datxContent.dvaData;
};

/**
 * Select all the items that is relevant to display in a graph
 * @param id
 */
const selectDatxItemsForGraph = (id: string) => {
  return createSelector(
    [
      selectDatxFilteredContentById(id),
      selectDatxOptionalFiltersById(id),
      selectYAxisDomainById(id),
      selectYAxisTickCountById(id),
      selectTimezoneById(id)
    ],
    (
      datxContent,
      filters,
      yAxisDomain,
      yAxisTickCount,
      timezone
    ): ItemsForGraph => {
      return {
        datxContent,
        filters,
        yAxisDomain,
        yAxisTickCount,
        timezone
      };
    }
  );
};

const selectContentAndFilters = (id: string) => {
  return createSelector(
    [selectDatxContentById(id), selectDatxOptionalFiltersById(id)],
    (datxContent, filters) => {
      return { datxContent, filters };
    }
  );
};

const selectFilteredContentAndFilters = (id: string) => {
  return createSelector(
    [selectDatxFilteredContentById(id), selectDatxOptionalFiltersById(id)],
    (datxContent, filters) => {
      return { datxContent, filters };
    }
  );
};
// state.openDatx.openFiles?.[id]?.scoreValuesCursorPosSelected;

/**
 * Select the currently active datx file according to activeFileId
 * @param state
 */
export const selectActiveDatxFile = (state: StoreApi) => {
  const { activeFileId, openFiles } = state.openDatx;

  if (isNil(activeFileId)) {
    return undefined;
  }

  return openFiles?.[activeFileId];
};

/**
 * Select domains. Intended to be used as an input selector.
 * @param id
 */
const selectActiveAndZoomDomain = (id: string) => {
  return createSelector(
    [selectActiveDomainById(id), selectZoomDomainById(id)],
    (activeDomain, zoomDomain) => {
      // Note: zoom domain should under normal circumstances never be nil, but
      // since it is based on the last element of the zoom stack (which is an
      // array) there is technically a possibility for that array to be empty (by
      // mistake) if that were to happen, we catch that "error" here with a backup
      // value
      if (isNil(zoomDomain)) {
        const backupZoomDomain = {
          x: activeDomain,
          y: yAxisDefaultNormalizedValues
        };

        return { activeDomain, zoomDomain: backupZoomDomain };
      }

      return { activeDomain, zoomDomain };
    }
  );
};

export const selectDatxByFileById = (id: string) => (state: StoreApi) => {
  return state.openDatx.openFiles?.[id];
};

export const selectRawDataById = (id: string) => (state: StoreApi) => {
  return state.openDatx.openFiles?.[id]?.rawData ?? undefined;
};

export const selectDashboardLayoutsById = (id: string) => (state: StoreApi) => {
  const { activeDashboard } = selectDatxByFileById(id)(state);
  const { available } = state.persistantState.dashboard;

  /* If user removes a Report that is currently active in another file,
  this sets layouts to Standard, preventing a crash. */
  if (available[activeDashboard]) {
    const { layouts } = available[activeDashboard];
    return { dashboardKey: activeDashboard, layouts };
  } else {
    const { layouts } = available["Standard"];
    return { dashboardKey: activeDashboard, layouts };
  }
};

// Used in the reports selector
export const selectDashboardInfoById = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const { activeDashboard } = activeFile;
  const { available } = state.persistantState.dashboard;

  /* If user removes a Report that is currently active in another file,
  this sets active to Standard, preventing a crash. */
  if (available[activeDashboard]) {
    const { name: active } = available[activeDashboard];
    return { active, available };
  } else {
    const { name: active } = available["Standard"];
    return { active, available };
  }
};

// Used to sync the active and available reports
export const selectSyncableReports = (state: StoreApi) => {
  const { activeFileId } = state.openDatx;
  const reports = state.persistantState.dashboard;
  return { activeFileId, reports };
};

export const selectActiveYAxisDomain = (state: StoreApi) => {
  return selectActiveDatxFile(state)?.yAxisDomain;
};

export const selectActiveYAxisTickCount = (state: StoreApi) => {
  return selectActiveDatxFile(state)?.yAxisTickCount;
};

const selectDatxContentById = (id: string) => (state: StoreApi) =>
  selectDatxByFileById(id)(state)?.datxContent;

const selectDatxUnlockedStateById = (id: string) => (state: StoreApi) =>
  selectDatxByFileById(id)(state)?.isUnlocked;

export const selectDatxOptionalFiltersById =
  (id: string) => (state: StoreApi) =>
    selectDatxByFileById(id)(state).filters;

export const selectExternalSensorParamsById =
  (id: string) => (state: StoreApi) =>
    selectDatxByFileById(id)(state)?.datxContent.recordingParameters
      ?.ExternalSensorParams;

export const selectAccParamsById = (id: string) => (state: StoreApi) =>
  selectDatxByFileById(id)(state)?.datxContent.recordingParameters?.AccParams;

const selectYAxisDomainById = (id: string) => (state: StoreApi) =>
  selectDatxByFileById(id)(state).yAxisDomain;

const selectYAxisTickCountById = (id: string) => (state: StoreApi) =>
  selectDatxByFileById(id)(state).yAxisTickCount;

export const selectTimezoneById = memoize((id: string) => (state: StoreApi) => {
  const timezoneState = state.persistantState.globalTimezoneState;
  const timezoneToggle = state.persistantState.globalTimezoneToggle;
  return timezoneSelector(
    selectDatxByFileById(id)(state).timezone,
    timezoneState,
    timezoneToggle
  );
});

const selectDataDomainById = (id: string) => (state: StoreApi) =>
  selectDatxByFileById(id)(state)?.dataDomain;

const selectActiveDomainById = (id: string) => (state: StoreApi) =>
  selectDatxByFileById(id)(state)?.activeDomain;

const selectZoomDomainById = (id: string) => (state: StoreApi) => {
  const { zoomDomainStack } = selectDatxByFileById(id)(state);

  return last(zoomDomainStack);
};

/**
 * Select data that is toggled on in the filters. This function is memoized for
 * performance reasons. If id is undefined, undefiend will be returned
 */
const selectFilteredDataById = memoize((id: string) => {
  return createSelector(
    selectContentAndFilters(id),
    selectFilteredDatxContentAndFilters
  );
});

// View model selectors, consists of selectors that does something with the
// state-date unlike input selectors that just picks it as is

/**
 * Selects info about open files as well as the currently active file
 * @param state
 */
export const selectAllOpenDatx = (state: StoreApi) => {
  const { openFiles, activeFileId } = state.openDatx;

  const openFilesArr = openFiles ? Object.values(openFiles) : [];

  return { openFiles: openFilesArr, activeFileId };
};

export type ItemsForGraph = {
  datxContent: FilteredDatxContent;
  filters: DataFilterStates;
  yAxisDomain: YAxisDomain;
  yAxisTickCount: YAxisTickCount;
  timezone: string;
};

/**
 * Select relevant datx-content + extras from state and create a view-model that
 * works for graphs. This selector is memoized for performance reasons
 */
export const selectDataForGraph = memoize((id) => {
  return createSelector(selectDatxItemsForGraph(id), (itemsForGraph) => {
    return createPrimaryGraphData(itemsForGraph);
  });
});

/** Select view model for status graph. Memoized for performance reasons */
export const selectDataForStatusGraph = memoize((id: string) =>
  createSelector(
    selectFilteredContentAndFilters(id),
    (contentAndFilters): StatusGraphData => {
      const { datxContent, filters } = contentAndFilters;
      const { recordingParameters } = datxContent;

      const angleParams = datxContent.recordingParameters?.AngleParams;

      /** Inline function used to termine if an angle is above alarm level */
      const isAngleWarning = isNil(angleParams)
        ? () => false
        : angleWarningChecker(angleParams);

      const angleData = isNil(angleParams)
        ? undefined
        : datxContent?.data?.reduce((arr: AngleGraphPoint[], curr) => {
            const { angle, timestamp } = curr;

            if (isNil(angle)) return arr;

            const [xAngle, yAngle, zAngle] = angle;

            arr.push({
              x: unix2Date(timestamp),
              y: 0,
              data: { xAngle, yAngle, zAngle },
              isWarning: isAngleWarning(angle)
            });

            return arr;
          }, [] as AngleGraphPoint[]);

      const startupData = datxContent?.startup?.map(
        (curr): ExtraStatusSymbol => ({
          type: "startup",
          x: unix2Date(curr),
          y: 7
        })
      );

      // Get y value for each status type
      const getY = (status: RunningStatusTypes) => {
        switch (status) {
          case "btnPress":
          case "tampering":
            return 9;
          case "recStart":
            return 5;
          case "recEndNormal":
          case "recEndMemoryFull":
          case "recEndWriteError":
          default:
            return 7;
        }
      };

      const runningStatusData = datxContent?.data?.reduce(
        (arr: ExtraStatusSymbol[], curr) => {
          const { runningStatus, timestamp } = curr;
          if (isUndefined(runningStatus)) return arr;
          runningStatus.forEach((status) => {
            arr.push({
              type: status,
              x: unix2Date(timestamp),
              y: getY(status)
            });
          });
          return arr;
        },
        []
      );

      const deviceHealthData = datxContent?.data?.reduce(
        (arr: DeviceHealthItem[], curr) => {
          const { deviceHealth, timestamp } = curr;
          if (isNil(deviceHealth)) return arr;

          arr.push({ x: unix2Date(timestamp), y: 11, data: deviceHealth });
          return arr;
        },
        []
      );

      return {
        recordingParameters,
        dataFilters: filters,
        angleData,
        startupData,
        runningStatusData,
        deviceHealthData
      };
    }
  )
);

/** Select view model for status graph. Memoized for performance reasons */
export const selectDataForExternalIOGraph = memoize((id: string) =>
  createSelector(
    selectFilteredContentAndFilters(id),
    (contentAndFilters): ExternalIOGraphData => {
      const { datxContent, filters } = contentAndFilters;
      const { recordingParameters } = datxContent;
      const { ExternalInputParams, ExternalOutputParams } = recordingParameters;

      const inputNames: string[] = ExternalInputParams.map(
        (input) => input.description
      );
      const outputNames: string[] = ExternalOutputParams.map(
        (output) => output.description
      );
      let externalInputsData: ExternalIO[] = [];
      let externalOutputsData: ExternalIO[] = [];
      datxContent?.data?.forEach((curr: RecordingDataBlockFiltered) => {
        const { externalInputs, externalOutputs, timestamp } = curr;
        if (!isNil(externalInputs)) {
          externalInputsData.push({
            x: unix2Date(timestamp),
            y: 0,
            data: {
              changed: externalInputs.changed,
              state: externalInputs.state,
              names: inputNames
            }
          });
        }

        if (!isNil(externalOutputs)) {
          externalOutputsData.push({
            x: unix2Date(timestamp),
            y: 0,
            data: {
              changed: externalOutputs.changed,
              state: externalOutputs.state,
              names: outputNames
            }
          });
        }
      });

      return {
        dataFilters: filters,
        externalInputs: externalInputsData,
        externalOutputs: externalOutputsData,
        externalInputParams: ExternalInputParams,
        externalOutputParams: ExternalOutputParams
      };
    }
  )
);

/** Select view model for external timers graph. */
export const selectDataForExternalTimersGraph = memoize((id: string) =>
  createSelector(
    selectFilteredContentAndFilters(id),
    (contentAndFilters): ExternalTimersGraphData => {
      const { datxContent, filters } = contentAndFilters;

      const externalTimers = datxContent?.data?.reduce(
        (arr: ExternalTimer[], curr) => {
          const { externalTimers, timestamp } = curr;
          if (isNil(externalTimers)) return arr;
          arr.push({ x: unix2Date(timestamp), y: 0, data: externalTimers });
          return arr;
        },
        []
      );

      return {
        dataFilters: filters,
        externalTimers
      };
    }
  )
);

/** Select view model for Quick Report. Memoized for performance reasons */
export const selectDataForQuickReport = memoize((id: string) =>
  createSelector(
    selectFilteredContentAndFilters(id),
    (contentAndFilters): QuickReportData => {
      const { datxContent } = contentAndFilters;

      const recParams = datxContent.recordingParameters;

      const angleParams = recParams?.AngleParams;
      const accParams = recParams?.AccParams;
      const tempParams = recParams?.TempParams;
      const rhParams = recParams?.RhParams;
      const pressureParams = recParams?.PressureParams;
      const extSensorParams = recParams?.ExternalSensorParams;

      /** Inline function used to termine if an angle is above alarm level */
      const isAngleWarning = isNil(angleParams)
        ? () => false
        : angleWarningChecker(angleParams);

      const isAccWarning = isNil(accParams)
        ? () => false
        : accWarningChecker(accParams);

      const isTempWarning = isNil(tempParams)
        ? () => false
        : tempWarningChecker(tempParams);

      const isRhWarning = isNil(rhParams)
        ? () => false
        : rhWarningChecker(rhParams);

      const isPressureWarning = isNil(pressureParams)
        ? () => false
        : pressureWarningChecker(pressureParams);

      const isExtTempWarning = (
        temp: number,
        param: IExternalRhTempParams
      ): boolean => {
        if (temp <= param.tempMin || temp >= param.tempMax) {
          return true;
        }
        return false;
      };

      const isExtRhWarning = (
        rh: number,
        param: IExternalRhTempParams
      ): boolean => {
        if (rh <= param.rhMin || rh >= param.rhMax) {
          return true;
        }
        return false;
      };

      let angleWarning: boolean | undefined = undefined;
      let accWarning: boolean | undefined = undefined;
      let tempWarning: boolean | undefined = undefined;
      let rhWarning: boolean | undefined = undefined;
      let pressureWarning: boolean | undefined = undefined;
      let extSensorTempWarnings: ExternalWarnings[] = [];
      let extSensorRhWarnings: ExternalWarnings[] = [];

      /** Loop through angles and check for warnings
       *  Save true or false to warning variable
       *  Stop looping if warning is found
       */
      // ANGLE
      datxContent?.data?.every((data) => {
        const current = data.angle ? isAngleWarning(data.angle) : undefined;
        if (!isUndefined(current)) {
          angleWarning = current;
        }
        return !current;
      });

      // ACC
      datxContent?.data?.every((data) => {
        const current =
          data.xAcc && data.yAcc && data.zAcc ? isAccWarning(data) : undefined;
        if (!isUndefined(current)) {
          accWarning = current;
        }
        return !current;
      });

      // TEMP
      datxContent?.data.every((data) => {
        const current = data.temp ? isTempWarning(data.temp) : undefined;
        if (!isUndefined(current)) {
          tempWarning = current;
        }
        return !current;
      });

      // RH
      datxContent?.data.every((data) => {
        const current = data.rh ? isRhWarning([data.rh, data.rh]) : undefined;
        if (!isUndefined(current)) {
          rhWarning = current;
        }
        return !current;
      });

      const isComp = datxContent.data.some(
        (data) => !isUndefined(data.pressureComp)
      );

      // Pressure
      datxContent.data.every((data) => {
        // Use pressureComp data if it exists, otherwhise use raw data
        const rawOrComp = isComp ? data.pressureComp : data.pressureRaw;
        const current = rawOrComp
          ? isPressureWarning([rawOrComp, rawOrComp])
          : undefined;
        if (!isUndefined(current)) {
          pressureWarning = current;
        }
        return !current;
      });

      // External TEMP
      extSensorParams.forEach((sensor) => {
        let newWarning: boolean = false;
        datxContent.data.every((data) => {
          const currentExtTemp =
            sensor.params.sensorConfig && !isUndefined(data.externalTemp?.temp)
              ? isExtTempWarning(
                  data.externalTemp.temp,
                  sensor.params.sensorConfig
                )
              : undefined;

          if (
            !isUndefined(currentExtTemp) &&
            sensor.params.sensorTypeId === data.externalTemp?.sensorId
          ) {
            newWarning = currentExtTemp;
          }

          return !currentExtTemp;
        });

        extSensorTempWarnings.push({
          sensorId: sensor.params.sensorTypeId,
          warning: newWarning
        });
      });

      // External RH
      extSensorParams.forEach((sensor) => {
        let newWarning: boolean = false;
        datxContent.data.every((data) => {
          const currentExtRh =
            sensor.params.sensorConfig && !isUndefined(data.externalRh?.rh)
              ? isExtRhWarning(data.externalRh.rh, sensor.params.sensorConfig)
              : undefined;

          if (
            !isUndefined(currentExtRh) &&
            sensor.params.sensorTypeId === data.externalRh?.sensorId
          ) {
            newWarning = currentExtRh;
          }

          return !currentExtRh;
        });

        extSensorRhWarnings.push({
          sensorId: sensor.params.sensorTypeId,
          warning: newWarning
        });
      });

      return {
        angleWarning,
        accWarning,
        tempWarning,
        rhWarning,
        pressureWarning,
        extSensorTempWarnings,
        extSensorRhWarnings,
        recParams
      };
    }
  )
);

/** Graph extra utility tools that is not directly connected to pure data. */
export const selectGraphTools = (state: StoreApi) => {
  const activeFile = selectActiveDatxFile(state);

  if (!activeFile) return undefined;

  const { scoreValuesCursorPos, contextLines, activeScoreValueCursor } =
    activeFile;

  const scoreValuesIsActive = activeScoreValueCursor === "primaryGraph";

  return {
    scoreValuesCursorPos,
    contextLines,
    scoreValuesIsActive
  };
};

export const selectDvaGraphTools = (state: StoreApi) => {
  const activeFile = selectActiveDatxFile(state);

  if (!activeFile) return undefined;

  const {
    dvaScoreValuesCursorPosIndex,
    activeScoreValueCursor,
    dvaContextLines
  } = activeFile;

  const scoreValuesIsActive = activeScoreValueCursor === "dvaGraph";

  return {
    dvaScoreValuesCursorPosIndex,
    dvaContextLines,
    scoreValuesIsActive
  };
};

export const selectGraphDomains = memoize((id: string) =>
  createSelector(selectActiveAndZoomDomain(id), (domains) => {
    const activeDomain = num2DateTuple(domains.activeDomain);

    const zoomDomain = {
      x: num2DateTuple(domains.zoomDomain.x),
      y: domains.zoomDomain.y
    };

    return { activeDomain, zoomDomain };
  })
);

export const selectProjectInfo = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const { recordingParameters } = activeFile.datxContent;
  const { filters } = activeFile;
  let isFiltered = false;
  if (
    filters.xAcc.showDynamicFilter ||
    filters.xAcc.hideDataWithinAlarmLevel ||
    filters.yAcc.showDynamicFilter ||
    filters.zAcc.showDynamicFilter ||
    filters.temp.hideDataWithinAlarmLevel ||
    filters.rh.hideDataWithinAlarmLevel ||
    filters.angle.hideDataWithinAlarmLevel ||
    Object.values(filters.extRh).some(
      (extRh) => extRh.hideDataWithinAlarmLevel
    ) ||
    Object.values(filters.extTemp).some(
      (extTemp) => extTemp.hideDataWithinAlarmLevel
    )
  )
    isFiltered = true;

  return {
    projectName: recordingParameters.ProjectName,
    isFiltered: isFiltered,
    projectStart: recordingParameters.RecParams.startTimestamp,
    projectEnd: recordingParameters.RecParams.stopTimestamp
  };
};

export const selectZoomTools = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);

  const { zoomDimension, zoomDomainStack } = activeFile;
  const hasZoomed = zoomDomainStack.length > 1;

  return { zoomDimension, hasZoomed };
};

/** General nice to have info for graph page */
export const selectGraphPageVm = memoize((state: StoreApi) => {
  const { activeFileId, openNewFileStatus } = state.openDatx;

  const hasOpenFiles = !isEmpty(state.openDatx.openFiles);
  const hasData =
    activeFileId && !isEmpty(selectDatxContentById(activeFileId)(state)?.data);
  const isUnlocked =
    activeFileId && selectDatxUnlockedStateById(activeFileId)(state);

  return { activeFileId, hasOpenFiles, hasData, openNewFileStatus, isUnlocked };
});

/**
 * Selects all data that is active in the filters. */
const selectFilteredDatxContentAndFilters = ({
  datxContent,
  filters
}: Pick<IOpenDatx, "datxContent" | "filters">) => {
  const { data, recordingParameters } = datxContent;

  return createFilteredDataset(filters, recordingParameters, data);
};

/** Select view model for score values component */
export const selectScoreValuesData =
  (id: string) =>
  (state: StoreApi): Optional<VMScoreValues> => {
    const activeFile = selectDatxByFileById(id)(state);

    const activeData = selectFilteredDataById(id)?.(state);

    const cursorPosUnix = activeFile.scoreValuesCursorPos;
    const cursorPosIndex = activeFile.scoreValuesCursorPosIndex;
    const timezone = activeFile.timezone;

    const timezoneState = state.persistantState.globalTimezoneState;
    const timezoneToggle = state.persistantState.globalTimezoneToggle;

    const cursorPos = cursorPosUnix
      ? createTzDate(
          cursorPosUnix,
          timezoneSelector(timezone, timezoneState, timezoneToggle)
        )
      : undefined;

    const filters = activeFile.filters;

    /** Inline function: get closest data point for specific type */
    const getNearestDataPointForType = <T extends DtChannel>(goal: {
      channel: T;
      timestamp: number;
      dataset: RecordingDataBlockFiltered[];
      esti?: number;
    }): Optional<GenericTimestampValue<DtValue<T>>> => {
      const res = getClosestDataPointToIndex(goal, cursorPosIndex);

      if (isNil(res) || isNil(res[goal.channel])) {
        return undefined;
      }

      const timestamp = createTzDate(
        res.timestamp,
        timezoneSelector(timezone, timezoneState, timezoneToggle)
      );
      //Note: I know it won't be undefined since I checked before
      const value = res[goal.channel] as ValueOf<
        Pick<Required<RecordingDataBlockUnique>, T>
      >;

      return {
        timestamp,
        value
      };
    };

    /** Inline function: check for close dva data */
    const checkIfAnyCloseDvaData = (timestamp?: number) => {
      const dvaData = activeFile?.datxContent?.dvaData;

      if (isNil(timestamp) || isNil(dvaData)) {
        return undefined;
      }

      const foundIndex = dvaData.findIndex((dva) => {
        const { start, end } = dva;
        return timestamp + 0.5 >= start && timestamp - 0.5 <= end;
      });

      return foundIndex === -1 ? undefined : foundIndex;
    };

    const canSearchForCloseDataPoint =
      !isNil(cursorPosUnix) && !isNil(activeData);

    const scoreValuesTarget = {
      timestamp: cursorPosUnix!,
      dataset: activeData!
    };

    const nearestXAcc =
      !filters.xAcc.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({ ...scoreValuesTarget, channel: "xAcc" });
    const nearestYAcc =
      !filters.yAcc.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({ ...scoreValuesTarget, channel: "yAcc" });
    const nearestZAcc =
      !filters.zAcc.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({ ...scoreValuesTarget, channel: "zAcc" });
    const nearestAccComponent = !canSearchForCloseDataPoint
      ? undefined
      : getNearestDataPointForType({
          ...scoreValuesTarget,
          channel: "accComponent"
        });
    const nearestTemp =
      !filters.temp.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({ ...scoreValuesTarget, channel: "temp" });
    const nearestRh =
      !filters.rh.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({ ...scoreValuesTarget, channel: "rh" });
    const nearestPressureRaw =
      !filters.pressureRaw.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({
            ...scoreValuesTarget,
            channel: "pressureRaw"
          });
    const nearestPressureComp =
      !filters.pressureComp.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({
            ...scoreValuesTarget,
            channel: "pressureComp"
          });
    const nearestAngle =
      !filters.angle.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({
            ...scoreValuesTarget,
            channel: "angle"
          });
    const nearestDeviceHealth = !canSearchForCloseDataPoint
      ? undefined
      : getNearestDataPointForType({
          ...scoreValuesTarget,
          channel: "deviceHealth"
        });
    const nearestGPS = !canSearchForCloseDataPoint
      ? undefined
      : getNearestDataPointForType({ ...scoreValuesTarget, channel: "gps" });

    const nearestExternalTimer =
      !filters.extTimer.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({
            ...scoreValuesTarget,
            channel: "externalTimers"
          });
    const nearestExternalTemps = Object.keys(filters.extTemp).map((esti) => {
      if (
        !canSearchForCloseDataPoint ||
        !filters.extTemp[esti].dataToggle.isActive
      ) {
        return undefined;
      } else {
        return getNearestDataPointForType({
          ...scoreValuesTarget,
          channel: "externalTemp",
          esti: parseInt(esti)
        });
      }
    });
    const nearestExternalRhs = Object.keys(filters.extRh).map((esti) => {
      if (
        !canSearchForCloseDataPoint ||
        !filters.extRh[esti].dataToggle.isActive
      ) {
        return undefined;
      } else {
        return getNearestDataPointForType({
          ...scoreValuesTarget,
          channel: "externalRh",
          esti: parseInt(esti)
        });
      }
    });
    const nearestExternalInput =
      !filters.extInput.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({
            ...scoreValuesTarget,
            channel: "externalInputs"
          });
    const nearestExternalOutput =
      !filters.extOutput.dataToggle.isActive || !canSearchForCloseDataPoint
        ? undefined
        : getNearestDataPointForType({
            ...scoreValuesTarget,
            channel: "externalOutputs"
          });
    const nearestExtSensMsg = !canSearchForCloseDataPoint
      ? undefined
      : getNearestDataPointForType({
          ...scoreValuesTarget,
          channel: "extSensMsg"
        });
    const nearestRunningStatus = !canSearchForCloseDataPoint
      ? undefined
      : getNearestDataPointForType({
          ...scoreValuesTarget,
          channel: "runningStatus"
        });

    const closeDvaDataKey = !isAccFilterActive(filters)
      ? undefined
      : checkIfAnyCloseDvaData(scoreValuesTarget.timestamp);

    return {
      cursorPos,
      nearestXAcc,
      nearestYAcc,
      nearestZAcc,
      nearestAccComponent,
      nearestTemp,
      nearestRh,
      nearestPressureRaw,
      nearestPressureComp,
      nearestAngle,
      nearestDeviceHealth,
      closeDvaDataKey,
      nearestGPS,
      nearestExternalTimer,
      nearestExternalTemps,
      nearestExternalRhs,
      nearestExternalInput,
      nearestExternalOutput,
      nearestRunningStatus,
      nearestExtSensMsg
    };
  };

// todo: should this be placed elsewhere?
export const selectExternalFilters = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const filters = activeFile.filters;

  return {
    extTemp: filters.extTemp,
    extRh: filters.extRh
  };
};

/** Get min/max data-object by id */
export const selectMinMaxData = memoize((id: string) => {
  return createSelector(
    [selectDataInActiveDomain(id), selectTimezoneById(id)],
    (activeData, timezone) => {
      return {
        data: getMinMaxDataset(activeData),
        timezone: timezone
      };
    }
  );
});

export const selectDvaDataForDashboard =
  (id: string) =>
  (state: StoreApi): DvaCardViewModel => {
    const activeFile = selectDatxByFileById(id)(state);

    const dvaData = selectDvaDataInActiveDomain(id)(state);
    const activeKey = activeFile?.activeDvaBlockKey;
    const markedKeys = activeFile?.markedDvaBlockKeys;
    const timezone = selectTimezoneById(id)(state);
    const zoomDomain =
      activeFile.dvaZoomDomain?.blockKey === activeKey
        ? last(activeFile.dvaZoomDomain?.zoomDomainStack)
        : undefined;
    const { dvaZoomDimension } = activeFile;
    const hasZoomed = zoomDomain !== undefined;

    return {
      dvaData,
      activeKey,
      markedKeys,
      timezone,
      zoomDomain,
      dvaZoomDimension,
      hasZoomed
    };
  };

export const selectDvaTableInfo = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);

  const dvaData = selectDvaDataInActiveDomain(id)(state);
  const activeKey = activeFile?.activeDvaBlockKey;
  const markedKeys = activeFile?.markedDvaBlockKeys;
  const timezone = activeFile.timezone;

  const { dvaActiveTablePage, dvaPaginationSize } = activeFile;

  return {
    dvaData,
    activeKey,
    markedKeys,
    timezone,
    dvaActiveTablePage,
    dvaPaginationSize
  };
};

//todo: should this be moved??
export const selectDatxToCsvFunc =
  (id: string, csvFormat: string) =>
  (state: StoreApi): (() => Optional<string>) => {
    const activeFile = selectDatxByFileById(id)(state);
    const extSensors =
      activeFile?.datxContent?.recordingParameters?.ExternalSensorParams;

    const timezone = activeFile.timezone;
    const timezoneState = state.persistantState.globalTimezoneState;
    const timezoneToggle = state.persistantState.globalTimezoneToggle;

    // If global timezone is selected use it.
    // Otherwise use active file timezone.
    const curTimezone = timezoneSelector(
      timezone,
      timezoneState,
      timezoneToggle
    );

    /** Wrap the rest so that it can be executed at a later stage */
    return () => {
      /** Cell separator in swedish or brittish form */
      const sep = csvFormat === "swe" ? ";" : ",";
      // dayjs.unix(unix).tz(tz)

      const utcOffset = dayjs().tz(curTimezone).utcOffset() / 60;

      const utcOffsetStr =
        utcOffset >= 0 ? `UTC+${utcOffset}` : `UTC${utcOffset}`;

      const csvHeader = `Timestamp (${utcOffsetStr})${sep}Acc X${sep}Dur X${sep}Acc Y${sep}Dur Y${sep}Acc Z${sep}Dur Z${sep}Acc Comp${sep}Temp${sep}Rh${sep}Pressure Raw${sep}Pressure Comp${sep}Angle X${sep}Angle Y${sep}Angle Z${sep}GPS Timestamp${sep}GPS Status${sep}GPS Lat${sep}GPS Lon${sep}GPS Velocity${sep}ESTI${sep}External Sensor Name${sep}Ext Temp Value${sep}Ext RH Value${sep}Running Status\n`;

      const { gpsTriggeredBySensor, gpsTriggeredBySchedule } =
        gpsStatusBitMasks;
      const triggerText = (status?: number) => {
        if (status === gpsTriggeredBySchedule) {
          return "Triggered by schedule";
        } else if (status === gpsTriggeredBySensor) {
          return "Triggered by sensor";
        }
        return "";
      };

      const setRunningStatusString = (statuses?: RunningStatusTypes[]) => {
        const statusArr: string[] = [];
        statuses?.forEach((status) => {
          if (status === "btnPress") return statusArr.push("Btn Press");
          if (status === "tampering")
            return statusArr.push("Tampering Detected");
          if (status === "recStart") return statusArr.push("Rec Started");
          if (status === "recEndNormal") return statusArr.push("Rec Ended");
          if (status === "recEndMemoryFull")
            return statusArr.push("Memory Full");
          if (status === "recEndWriteError")
            return statusArr.push("Write Error");
          return "";
        });
        return statusArr.join(", ");
      };

      /**
       * Returns true if all cols equals empty string
       * @param cols Array of future csv-cols except timestamp
       */
      const hasNoValuesToShow = (cols: string[]) =>
        cols.filter((x) => x !== "").length === 0;

      return activeFile?.datxContent?.data?.reduce((arr: string, curr) => {
        const extTempParams = extSensors.find(
          (sensor) => sensor.params.sensorTypeId === curr.externalTemp?.sensorId
        );

        const timestamp = dayjs
          .unix(curr.timestamp)
          .tz(curTimezone)
          .format("YYYY-MM-DD HH:mm:ss");
        // Acc
        const accX = floatToLocalizedString(curr?.xAcc?.[0], csvFormat);
        const accXDurr = floatToLocalizedString(curr?.xAcc?.[1], csvFormat);
        const accY = floatToLocalizedString(curr?.yAcc?.[0], csvFormat);
        const accYDurr = floatToLocalizedString(curr?.yAcc?.[1], csvFormat);
        const accZ = floatToLocalizedString(curr?.zAcc?.[0], csvFormat);
        const accZDurr = floatToLocalizedString(curr?.zAcc?.[1], csvFormat);
        let accCompValue: number | undefined = undefined;
        if (
          !isUndefined(curr?.xAcc) ||
          !isUndefined(curr?.yAcc) ||
          !isUndefined(curr?.zAcc)
        ) {
          accCompValue = Math.sqrt(
            Math.pow(curr?.xAcc?.[0] ?? 0, 2) +
              Math.pow(curr?.yAcc?.[0] ?? 0, 2) +
              Math.pow(curr?.zAcc?.[0] ?? 0, 2)
          );
        }
        const accComp = floatToLocalizedString(accCompValue, csvFormat);
        // Internal Temp and RH
        const temp = floatToLocalizedString(curr?.temp, csvFormat);
        const rh = floatToLocalizedString(curr?.rh, csvFormat);
        // Pressure
        const pressureRaw = floatToLocalizedString(
          curr?.pressureRaw,
          csvFormat
        );
        const pressureComp = floatToLocalizedString(
          curr?.pressureComp,
          csvFormat
        );
        // Angle
        const angleX = floatToLocalizedString(curr?.angle?.[0], csvFormat);
        const angleY = floatToLocalizedString(curr?.angle?.[1], csvFormat);
        const angleZ = floatToLocalizedString(curr?.angle?.[2], csvFormat);
        // GPS
        const gpsTimestamp = curr.gps
          ? dayjs
              .unix(curr.gps?.gpsTimestamp)
              .tz(curTimezone)
              .format("YYYY-MM-DD HH:mm:ss")
          : "";
        const gpsStatus = triggerText(curr?.gps?.gpsStatus);
        const gpsLatitude = floatToLocalizedString(
          curr?.gps?.latitude,
          csvFormat
        );
        const gpsLongitude = floatToLocalizedString(
          curr?.gps?.longitude,
          csvFormat
        );
        const gpsVelocity = floatToLocalizedString(
          curr?.gps?.velocity,
          csvFormat
        );
        // External Sensors
        // If external temp sensor is found RH will exist and have the same value
        const esti = curr?.externalTemp
          ? curr?.externalTemp?.sensorId.toString(16)
          : "";
        const extSensorName = extTempParams
          ? extTempParams.params.sensorName
          : "";
        // External Temp
        const extTempValue = floatToLocalizedString(
          curr?.externalTemp?.temp,
          csvFormat
        );
        // External RH
        const extRhValue = floatToLocalizedString(
          curr?.externalRh?.rh,
          csvFormat
        );
        const runningStatus = setRunningStatusString(curr?.runningStatus);
        if (
          hasNoValuesToShow([
            accX,
            accXDurr,
            accY,
            accYDurr,
            accZ,
            accZDurr,
            accComp,
            temp,
            rh,
            pressureRaw,
            pressureComp,
            angleX,
            angleY,
            angleZ,
            gpsTimestamp,
            gpsStatus,
            gpsLatitude,
            gpsLongitude,
            gpsVelocity,
            esti,
            extSensorName,
            extTempValue,
            extRhValue,
            runningStatus
          ])
        ) {
          return arr;
        }

        return arr.concat(
          `${timestamp}${sep}${accX}${sep}${accXDurr}${sep}${accY}${sep}${accYDurr}${sep}${accZ}${sep}${accZDurr}${sep}${accComp}${sep}${temp}${sep}${rh}${sep}${pressureRaw}${sep}${pressureComp}${sep}${angleX}${sep}${angleY}${sep}${angleZ}${sep}${gpsTimestamp}${sep}${gpsStatus}${sep}${gpsLatitude}${sep}${gpsLongitude}${sep}${gpsVelocity}${sep}${esti}${sep}${extSensorName}${sep}${extTempValue}${sep}${extRhValue}${sep}${runningStatus}\n`
        );
      }, csvHeader);
    };
  };

export interface AccData {
  xAcc: [number, number];
  yAcc: [number, number];
  zAcc: [number, number];
  timestamp: number;
  timezone: string;
}
export const selectAccData = memoize((id: string) => {
  return createSelector(
    [selectDataInActiveDomain(id), selectTimezoneById(id)],
    (dataInDomain, timezone) => {
      return dataInDomain.reduce((arr: AccData[], curr) => {
        const { xAcc, yAcc, zAcc, timestamp } = curr;
        if (xAcc && yAcc && zAcc) {
          arr.push({
            xAcc,
            yAcc,
            zAcc,
            timestamp,
            timezone
          });
        }
        return arr;
      }, []);
    }
  );
});

export interface AngleData {
  key: number;
  timestamp: number;
  xAngle: number;
  yAngle: number;
  zAngle: number;
}
export const selectAngleData = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);

  return activeFile.datxContent.data.reduce((arr: AngleData[], curr, index) => {
    const { angle } = curr;
    if (angle) {
      arr.push({
        key: index,
        timestamp: curr.timestamp,
        xAngle: angle[0],
        yAngle: angle[1],
        zAngle: angle[2]
      });
    }
    return arr;
  }, []);
};

export const selectAccHistogramData = memoize((id: string) => {
  return createSelector(
    [selectDataInActiveDomain(id), selectAccHistogramBins(id)],
    (dataInDomain, histogramBins) => {
      /** Histogram bins repressented with normal numbers */
      const logicBins = histogramBins.bins;

      /** Reducer init object */
      const init = logicBins.map((v) => ({
        x: { x: v, y: 0 },
        y: { x: v, y: 0 },
        z: { x: v, y: 0 }
      }));

      // Pre-binning data
      const dataWithLogicBins = dataInDomain.reduce((resp, curr) => {
        const { xAcc, yAcc, zAcc } = curr;

        if (!(xAcc && yAcc && zAcc)) {
          return resp;
        }

        const accWithDuration = getAccWithDuration({ xAcc, yAcc, zAcc });

        logicBins.reduce((bin, nextBin, index) => {
          if (accWithDuration.value >= bin && accWithDuration.value < nextBin) {
            /** the index that is supplied by the reducer corresponds to the
             * b-value. We want to to increase the lower bin (a-value) */
            const aIndex = index - 1;

            resp[aIndex][accWithDuration.axis].y += 1;
          }

          return nextBin;
        });

        return resp;
      }, init);

      //creating view models

      /** View model bins. Values are repressented as string-labels instead of numbers */
      const bins: string[] = logicBins.reduce(
        (resp: string[], curr, index, arr) => {
          //Last item, just return the result
          if (index === arr.length - 1) {
            return resp;
          }

          const nextItem = arr[index + 1];

          //current item and next item, e.g. 2 and 4 => "2-4"
          return resp.concat(`${curr}-${nextItem}`);
        },
        []
      );

      /** View model data. Replacing logical bins with view model bins */
      const data: AccHistogramData[] = bins.map((binTitle, index) => ({
        x: { x: binTitle, y: dataWithLogicBins[index].x.y },
        y: { x: binTitle, y: dataWithLogicBins[index].y.y },
        z: { x: binTitle, y: dataWithLogicBins[index].z.y }
      }));

      return { data, bins };
    }
  );
});

export const selectAccHistogramBins = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);

  return activeFile.histogramBins;
};

export const selectDashboardHeader =
  (id: string) =>
  (state: StoreApi): VMDashboardHeader | undefined => {
    const activeFile = selectDatxByFileById(id)(state);

    const { filePath } = activeFile;
    const { recordingParameters, systemInfo, header } = activeFile.datxContent;

    const fileName = filePath;

    return {
      recordingParameters,
      serialNumber: header.fat100Serial.toString(),
      systemInfo,
      fileName,
      filePath
    };
  };

export const selectDashboardFilters = (id: string) => (state: StoreApi) => {
  const { filters, activeDomain, timezone } = selectDatxByFileById(id)(state);

  return { optionalFilters: filters, activeDomain, timezone };
};

export const selectForDashboardRangePicker = (id: string) => {
  return createSelector(
    [
      selectActiveDomainById(id),
      selectDataDomainById(id),
      selectTimezoneById(id)
    ],
    (activeDomain, dataDomain, timezone) => {
      return { activeDomain, dataDomain, timezone };
    }
  );
};

/** Selects currently active item HEADERS in DASHBOARD */
export const selectReportCardHeaders = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);

  const availableLayoutIds = getAllAvailablePrintableLayouts(activeFile);
  const reportHeaders = selectReportHeaders(id, true)(state);

  return () => availableLayoutIds.flatMap((id) => reportHeaders[id]?.());
};

/** Selects currently active EXPORT items in DASHBOARD */
export const selectExportableDashboardCardsLazy =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);
    // All available cards for current data set
    const availableLayoutIds = getAllAvailablePrintableLayouts(activeFile);
    const layoutExportableOutcomes = selectLayoutExportableOutcomes(
      id,
      true
    )(state);

    return () =>
      availableLayoutIds.flatMap((id) => layoutExportableOutcomes[id]?.());
  };

/** Selects currently active PRINT items in DASHBOARD */
export const selectPrintableDashboardCardsLazy =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    const availableLayoutIds = getAllAvailablePrintableLayouts(activeFile);
    const layoutPrintablesOutcomes = selectLayoutPrintableOutcomes(
      id,
      true
    )(state);

    return () =>
      availableLayoutIds.flatMap((id) => layoutPrintablesOutcomes[id]?.());
  };

/** Selects currently selected item HEADERS in PREVIEW */
export const selectReportPreviewHeaders = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);

  const activeLayouts = activeFile.printLayoutIds;
  const layoutExportableOutcomes = selectReportHeaders(id, false)(state);

  return () => activeLayouts.flatMap((id) => layoutExportableOutcomes[id]?.());
};

/** Selects ItemHeaderData for currently selected item */
export const selectTransportReportItemHeaders =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    const activeLayouts = activeFile.printLayoutIds;
    const layoutExportableOutcomes = selectReportItemHeaders(id)(state);

    return () =>
      activeLayouts.flatMap((id) => layoutExportableOutcomes[id]?.());
  };

/** Selects transportHeaderData for current file */
export const selectTransportReportHeader =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);
    const projectInfo = selectProjectInfo(id)(state);
    const domains = selectGraphDomains(id)(state);

    const { dataDomain, timezone } = activeFile;
    const timezoneState = state.persistantState.globalTimezoneState;
    const timezoneToggle = state.persistantState.globalTimezoneToggle;
    const curTimezone = timezoneSelector(
      timezone,
      timezoneState,
      timezoneToggle
    );

    const entireDomain = createDateTupleWithOffset(dataDomain!, curTimezone);
    const utcOffsetStr = createUtcOffsetStr(curTimezone);

    const transportHeader: TransportHeaderData = {
      projectName: projectInfo.projectName,
      hasFilters: projectInfo.isFiltered,
      recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
      recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
      reportStart:
        dayjs(domains.zoomDomain.x[0])
          .tz(curTimezone)
          .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
      reportEnd:
        dayjs(domains.zoomDomain.x[1])
          .tz(curTimezone)
          .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
      exportedBy: getUser(state),
      deviceId: activeFile.datxContent.header.fat100Serial.toString()
    };
    return transportHeader;
  };

/** Selects currently selected EXPORT items in PREVIEW */
export const selectPreviewExportableDashboardCardsLazy =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    const activeLayouts = activeFile.printLayoutIds;
    const layoutExportableOutcomes = selectLayoutExportableOutcomes(
      id,
      false
    )(state);

    return () =>
      activeLayouts.flatMap((id) => layoutExportableOutcomes[id]?.());
  };

/** Selects currently selected PRINT items in PREVIEW */
export const selectPreviewPrintableDashboardCardsLazy =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    const activeLayouts = activeFile.printLayoutIds;
    const layoutPrintablesOutcomes = selectLayoutPrintableOutcomes(
      id,
      false
    )(state);

    return () =>
      activeLayouts.flatMap((id) => layoutPrintablesOutcomes[id]?.());
  };

/** Selects active and available layouts for print */
export const selectAvailableAndActivePrintableDashboardCardsIds =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    const activeLayoutIds = activeFile.printLayoutIds;
    const availableLayoutIds = getAllAvailablePrintableLayouts(activeFile);

    return { activeLayoutIds, availableLayoutIds };
  };

/** Print Dashboard content
 * - Selects a lookup map where the value is a function that returns a printable
 * component. If there isn't sufficent data in the state to display the
 * component, an empty array will be returned instead
 * @param id fileId
 * @param dashboardBtn True if button is pressed in Dashboard, false in Preview print
 */
const selectLayoutPrintableOutcomes =
  (id: string, dashboardBtn: boolean) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);
    const currentDashboard = selectDashboardLayoutsById(id)(state);

    // Cards on current dashboard
    const cards = currentDashboard.layouts.xl.map((card) => card.i);

    // If print from dashboard return only active cards
    // If print from preview return all cards
    const layoutPrintablesOutcomes: Record<
      LayoutId,
      () => PrintableItem<any>[]
    > = {
      quickReport: () => {
        const data = selectDataForQuickReport(id)(state) ?? {};

        if (isNil(data)) {
          return [];
        }

        if (dashboardBtn) {
          if (cards.includes("quickReport")) {
            return [getQuickReportPrintableItem({ data: data, fileId: id })];
          } else {
            return [];
          }
        }

        return [getQuickReportPrintableItem({ data: data, fileId: id })];
      },
      mainGraph: () => {
        const data = selectDataForGraph(id)(state);
        const zoomDomain = {
          x: num2DateTuple(
            last(activeFile.zoomDomainStack)?.x ?? yAxisDefaultNormalizedValues
          ),
          y: yAxisDefaultNormalizedValues
        };

        /** 'availableLayouts' contains IDs of the cards that are available in the current dashboard, ie, the cards that are not in the dashboard. */
        if (dashboardBtn) {
          if (cards.includes("mainGraph")) {
            return [
              getPrimaryGraphPrintableItem({
                data,
                zoomDomain,
                previewMode: true
              })
            ];
          } else {
            return [];
          }
        }

        return [
          getPrimaryGraphPrintableItem({ data, zoomDomain, previewMode: true })
        ];
      },
      deviceInfo: () => {
        const { recordingParameters } = activeFile.datxContent;

        if (isNil(recordingParameters)) {
          return [];
        }

        const data =
          createVmGeneralRecordingInformationCard(recordingParameters);

        if (dashboardBtn) {
          if (cards.includes("deviceInfo")) {
            return [getGeneralRecordingInfoPrintableItem({ data })];
          } else {
            return [];
          }
        }

        return [getGeneralRecordingInfoPrintableItem({ data })];
      },
      minMax: () => {
        const { timezone, datxContent } = activeFile;

        const minMaxData = getMinMaxDataset(datxContent.data);

        if (isEmpty(minMaxData)) {
          return [];
        }

        const timezoneState = state.persistantState.globalTimezoneState;
        const timezoneToggle = state.persistantState.globalTimezoneToggle;
        const dataObject = getMinMaxTableData(
          minMaxData,
          timezone,
          timezoneState,
          timezoneToggle
        );

        if (dashboardBtn) {
          if (cards.includes("minMax")) {
            return [getMinMaxTablePrintableItem({ dataObject })];
          } else {
            return [];
          }
        }

        return [getMinMaxTablePrintableItem({ dataObject })];
      },
      dvaGraph: () => {
        const { dvaData, markedKeys } =
          selectDvaDataForDashboard(id)(state) ?? {};
        const numPerPage = 2;

        if (isNil(dvaData) || isNil(markedKeys)) {
          return [];
        }

        return getDvaGraphsFromMarked(dvaData, markedKeys, numPerPage);
      },
      topAccTable: () => {
        const allAccData = selectAccData(id)(state);

        if (isNil(allAccData)) {
          return [];
        }

        const accData = getTop10(allAccData);

        if (dashboardBtn) {
          if (cards.includes("topAccTable")) {
            return [getTopAccTablePrintableItem({ accData })];
          } else {
            return [];
          }
        }

        return [getTopAccTablePrintableItem({ accData })];
      },
      accHistogram: () => {
        const { data, bins } = selectAccHistogramData(id)(state) ?? {};

        if (isNil(data) || isNil(bins)) {
          return [];
        }

        if (dashboardBtn) {
          if (cards.includes("accHistogram")) {
            return [getAccHistogramGraphPrintableItem({ data, bins })];
          } else {
            return [];
          }
        }

        return [getAccHistogramGraphPrintableItem({ data, bins })];
      },
      // work on this later
      map: () => {
        return [];
      },
      lte: () => {
        return [];
      },
      extTimer: () => {
        const { externalTimers } = selectDataForExternalTimersGraph(id)(state);
        const timezone = selectTimezoneById(id)(state);

        if (dashboardBtn) {
          if (cards.includes("extTimer")) {
            return [
              getExtTimersTablePrintableItem({
                data: externalTimers ?? [],
                timezone
              })
            ];
          } else {
            return [];
          }
        }

        return [
          getExtTimersTablePrintableItem({
            data: externalTimers ?? [],
            timezone
          })
        ];
      },
      extIO: () => {
        const data = selectDataForExternalIOGraph(id)(state);
        const timezone = selectTimezoneById(id)(state);

        if (dashboardBtn) {
          if (cards.includes("extIO")) {
            return [getExtIOTablePrintableItem({ data, timezone })];
          } else {
            return [];
          }
        }

        return [getExtIOTablePrintableItem({ data, timezone })];
      },
      angle: () => {
        // Create a new function that creates the neccecary data for angle table and exports
        const angleData = selectAngleData(id)(state);
        const timezone = selectTimezoneById(id)(state);

        if (dashboardBtn) {
          if (cards.includes("angle")) {
            return [
              getAngleTablePrintableItem({
                data: angleData,
                timezone
              })
            ];
          } else {
            return [];
          }
        }

        return [
          getAngleTablePrintableItem({
            data: angleData,
            timezone
          })
        ];
      }
    };

    return layoutPrintablesOutcomes;
  };

/** Export dashboard headers
 * Returns a Record of header data for each report card
 * @param id fileId
 * @param dashboardBtn True if button is pressed in Dashboard, false in Preview print
 */
const selectReportHeaders =
  (id: string, dashboardBtn: boolean) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);
    const currentDashboard = selectDashboardLayoutsById(id)(state);
    const projectInfo = selectProjectInfo(id)(state);
    const domains = selectGraphDomains(id)(state);

    const { dataDomain, timezone } = activeFile;
    const timezoneState = state.persistantState.globalTimezoneState;
    const timezoneToggle = state.persistantState.globalTimezoneToggle;
    const curTimezone = timezoneSelector(
      timezone,
      timezoneState,
      timezoneToggle
    );

    const entireDomain = createDateTupleWithOffset(dataDomain!, curTimezone);
    const utcOffsetStr = createUtcOffsetStr(curTimezone);

    const transportHeader: TransportHeaderData = {
      projectName: projectInfo.projectName,
      hasFilters: projectInfo.isFiltered,
      parametersLoaded:
        entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
      scheduledToEnd: {
        time: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
        duration: undefined
      },
      reportStart:
        dayjs(domains.zoomDomain.x[0])
          .tz(curTimezone)
          .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
      reportEnd:
        dayjs(domains.zoomDomain.x[1])
          .tz(curTimezone)
          .format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
      exportedBy: getUser(state),
      deviceId: activeFile.datxContent.header.fat100Serial.toString()
    };

    // Cards on current dashboard
    const cards = currentDashboard.layouts.xl.map((card) => card.i);

    const layoutExportableHeaderOutcomes: Record<
      LayoutId,
      () => TransportHeaderData[]
    > = {
      quickReport: () => {
        if (dashboardBtn) {
          if (cards.includes("quickReport")) {
            return [
              {
                ...transportHeader,
                reportType: "QuickReport"
              }
            ];
          } else {
            return [];
          }
        }

        return [
          {
            ...transportHeader,
            reportType: "QuickReport"
          }
        ];
      },
      mainGraph: () => {
        if (dashboardBtn) {
          if (cards.includes("mainGraph")) {
            return [
              {
                ...transportHeader,
                reportType: "PrimaryGraph"
              }
            ];
          } else {
            return [];
          }
        }

        return [
          {
            ...transportHeader,
            reportType: "PrimaryGraph"
          }
        ];
      },
      deviceInfo: () => {
        if (dashboardBtn) {
          if (cards.includes("deviceInfo")) {
            return [
              {
                ...transportHeader,
                reportType: "RecordingInformation"
              }
            ];
          } else {
            return [];
          }
        }

        return [
          {
            ...transportHeader,
            reportType: "RecordingInformation"
          }
        ];
      },
      minMax: () => {
        if (dashboardBtn) {
          if (cards.includes("minMax")) {
            return [
              {
                ...transportHeader,
                reportType: "MinMaxValues"
              }
            ];
          } else {
            return [];
          }
        }

        return [
          {
            ...transportHeader,
            reportType: "MinMaxValues"
          }
        ];
      },
      dvaGraph: () => {
        const dvaData = selectDvaDataInActiveDomain(id)(state);
        const markedKeys = activeFile?.markedDvaBlockKeys;
        const dvaHeaders = getExportableDvaHeadersFromSelected(
          dvaData,
          markedKeys,
          curTimezone
        );

        let headerArray: any = [];

        dvaHeaders.forEach((dvaHeader) => {
          headerArray.push({
            ...dvaHeader,
            reportType: "DetailedVibrationAnalysis"
          });
        });

        if (cards.includes("dvaGraph") && markedKeys.length > 0) {
          return headerArray;
        } else {
          return [];
        }
      },
      topAccTable: () => {
        if (dashboardBtn) {
          if (cards.includes("topAccTable")) {
            return [
              {
                ...transportHeader,
                reportType: "Top10Accelerations"
              }
            ];
          } else {
            return [];
          }
        }

        return [
          {
            ...transportHeader,
            reportType: "Top10Accelerations"
          }
        ];
      },
      accHistogram: () => {
        if (dashboardBtn) {
          if (cards.includes("accHistogram")) {
            return [
              {
                ...transportHeader,
                reportType: "AccelerationHistogram"
              }
            ];
          } else {
            return [];
          }
        }

        return [
          {
            ...transportHeader,
            reportType: "AccelerationHistogram"
          }
        ];
      },
      // change this later
      map: () => {
        return [];
      },
      lte: () => {
        return [];
      },
      extTimer: () => {
        if (dashboardBtn) {
          if (cards.includes("extTimer")) {
            return [
              {
                ...transportHeader,
                reportType: "extTimer"
              }
            ];
          } else {
            return [];
          }
        }
        return [
          {
            ...transportHeader,
            reportType: "extTimer"
          }
        ];
      },
      extIO: () => {
        if (dashboardBtn) {
          if (cards.includes("extIO")) {
            return [
              {
                ...transportHeader,
                reportType: "extIO"
              }
            ];
          } else {
            return [];
          }
        }
        return [
          {
            ...transportHeader,
            reportType: "extIO"
          }
        ];
      },
      angle: () => {
        if (dashboardBtn) {
          if (cards.includes("angle")) {
            return [
              {
                ...transportHeader,
                reportType: "angle"
              }
            ];
          } else {
            return [];
          }
        }
        return [
          {
            ...transportHeader,
            reportType: "angle"
          }
        ];
      }
    };

    return layoutExportableHeaderOutcomes;
  };

/** Export report item headers */
const selectReportItemHeaders = (id: string) => (state: StoreApi) => {
  const activeFile = selectDatxByFileById(id)(state);
  const currentDashboard = selectDashboardLayoutsById(id)(state);

  const { timezone } = activeFile;
  const timezoneState = state.persistantState.globalTimezoneState;
  const timezoneToggle = state.persistantState.globalTimezoneToggle;
  const curTimezone = timezoneSelector(timezone, timezoneState, timezoneToggle);

  // Cards on current dashboard
  const cards = currentDashboard.layouts.xl.map((card) => card.i);

  const layoutExportableHeaderOutcomes: Record<
    LayoutId,
    () => ItemHeaderData[]
  > = {
    quickReport: () => {
      return [
        {
          itemType: "QuickReport",
          itemTitle: "QuickReport"
        }
      ];
    },
    mainGraph: () => {
      return [
        {
          itemType: "PrimaryGraph",
          itemTitle: "PrimaryGraph"
        }
      ];
    },
    deviceInfo: () => {
      return [
        {
          itemType: "RecordingInformation",
          itemTitle: "RecordingInformation"
        }
      ];
    },
    minMax: () => {
      return [
        {
          itemType: "MinMaxValues",
          itemTitle: "MinMaxValues"
        }
      ];
    },
    dvaGraph: () => {
      const dvaData = selectDvaDataInActiveDomain(id)(state);
      const markedKeys = activeFile?.markedDvaBlockKeys;
      const dvaHeaders = getExportableDvaHeadersFromSelected(
        dvaData,
        markedKeys,
        curTimezone
      );

      if (cards.includes("dvaGraph") && markedKeys.length > 0) {
        return dvaHeaders;
      } else {
        return [];
      }
    },
    topAccTable: () => {
      return [
        {
          itemType: "Top10Accelerations",
          itemTitle: "Top10Accelerations"
        }
      ];
    },
    accHistogram: () => {
      return [
        {
          itemType: "AccelerationHistogram",
          itemTitle: "AccelerationHistogram"
        }
      ];
    },
    // change this later
    map: () => {
      return [];
    },
    lte: () => {
      return [];
    },
    extTimer: () => {
      return [
        {
          itemType: "extTimer",
          itemTitle: "extTimer"
        }
      ];
    },
    extIO: () => {
      return [
        {
          itemType: "extIO",
          itemTitle: "extIO"
        }
      ];
    },
    angle: () => {
      return [
        {
          itemType: "angle",
          itemTitle: "angle"
        }
      ];
    }
  };

  return layoutExportableHeaderOutcomes;
};

/** Export Dashboard content
 * - Selects a lookup map where the value is a function that returns a printable
 * component. If there isn't sufficent data in the state to display the
 * component, an empty array will be returned instead
 * @param id fileId
 * @param dashboardBtn True if button is pressed in Dashboard, false in Preview print
 */
const selectLayoutExportableOutcomes =
  (id: string, dashboardBtn: boolean) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);
    const currentDashboard = selectDashboardLayoutsById(id)(state);

    // Cards on current dashboard
    const cards = currentDashboard.layouts.xl.map((card) => card.i);

    const layoutExportableOutcomes: Record<LayoutId, () => ExportableItem[]> = {
      quickReport: () => {
        const data = selectDataForQuickReport(id)(state) ?? {};

        if (isNil(data)) {
          return [];
        }

        if (dashboardBtn) {
          if (cards.includes("quickReport")) {
            return [getQuickReportExportableItem({ data, fileId: id })];
          } else {
            return [];
          }
        }

        return [getQuickReportExportableItem({ data, fileId: id })];
      },
      mainGraph: () => {
        const data = selectDataForGraph(id)(state);
        const zoomDomain = {
          x: num2DateTuple(
            last(activeFile.zoomDomainStack)?.x ?? yAxisDefaultNormalizedValues
          ),
          y: yAxisDefaultNormalizedValues
        };

        if (dashboardBtn) {
          if (cards.includes("mainGraph")) {
            return [
              getPrimaryGraphExportableItem({
                data,
                zoomDomain,
                previewMode: true
              })
            ];
          } else {
            return [];
          }
        }

        return [
          getPrimaryGraphExportableItem({ data, zoomDomain, previewMode: true })
        ];
      },
      deviceInfo: () => {
        const { recordingParameters } = activeFile.datxContent;

        if (isNil(recordingParameters)) {
          return [];
        }

        const data =
          createVmGeneralRecordingInformationCard(recordingParameters);

        if (dashboardBtn) {
          if (cards.includes("deviceInfo")) {
            return [getGeneralRecordingInfoExportableItem({ data })];
          } else {
            return [];
          }
        }

        return [getGeneralRecordingInfoExportableItem({ data })];
      },

      minMax: () => {
        const { timezone, datxContent } = activeFile;

        const minMaxData = getMinMaxDataset(datxContent.data);

        if (isEmpty(minMaxData)) {
          return [];
        }

        const timezoneState = state.persistantState.globalTimezoneState;
        const timezoneToggle = state.persistantState.globalTimezoneToggle;
        const dataObject = getMinMaxTableData(
          minMaxData,
          timezone,
          timezoneState,
          timezoneToggle
        );

        if (dashboardBtn) {
          if (cards.includes("minMax")) {
            return [getMinMaxTableExportableItem({ dataObject })];
          } else {
            return [];
          }
        }

        return [getMinMaxTableExportableItem({ dataObject })];
      },
      dvaGraph: () => {
        const { dvaData, markedKeys } =
          selectDvaDataForDashboard(id)(state) ?? {};

        if (isNil(dvaData) || isNil(markedKeys)) {
          return [];
        }

        if (dashboardBtn) {
          if (cards.includes("dvaGraph") && markedKeys.length > 0) {
            return getExportableDvaGraphsFromSelected(dvaData, markedKeys);
          } else {
            return [];
          }
        }

        return getExportableDvaGraphsFromSelected(dvaData, markedKeys);
      },
      topAccTable: () => {
        const allAccData = selectAccData(id)(state);

        if (isNil(allAccData)) {
          return [];
        }

        const accData = getTop10(allAccData);

        if (dashboardBtn) {
          if (cards.includes("topAccTable")) {
            return [getTopAccTableExportableItem({ accData })];
          } else {
            return [];
          }
        }

        return [getTopAccTableExportableItem({ accData })];
      },
      accHistogram: () => {
        const { data, bins } = selectAccHistogramData(id)(state) ?? {};

        if (isNil(data) || isNil(bins)) {
          return [];
        }

        if (dashboardBtn) {
          if (cards.includes("accHistogram")) {
            return [getAccHistogramGraphExportableItem({ data, bins })];
          } else {
            return [];
          }
        }

        return [getAccHistogramGraphExportableItem({ data, bins })];
      },
      // change this later
      map: () => {
        return [];
      },
      lte: () => {
        return [];
      },
      extTimer: () => {
        const { externalTimers } = selectDataForExternalTimersGraph(id)(state);
        const timezone = selectTimezoneById(id)(state);

        if (dashboardBtn) {
          if (cards.includes("extTimer")) {
            return [
              getExtTimersTableExportableItem({
                data: externalTimers ?? [],
                timezone
              })
            ];
          } else {
            return [];
          }
        }

        return [
          getExtTimersTableExportableItem({
            data: externalTimers ?? [],
            timezone
          })
        ];
      },
      extIO: () => {
        const data = selectDataForExternalIOGraph(id)(state);
        const timezone = selectTimezoneById(id)(state);

        if (dashboardBtn) {
          if (cards.includes("extIO")) {
            return [getExtIOTableExportableItem({ data, timezone })];
          } else {
            return [];
          }
        }

        return [getExtIOTableExportableItem({ data, timezone })];
      },
      angle: () => {
        const angleData = selectAngleData(id)(state);
        const timezone = selectTimezoneById(id)(state);

        if (dashboardBtn) {
          if (cards.includes("angle")) {
            return [
              getAngleTableExportableItem({
                data: angleData,
                timezone
              })
            ];
          } else {
            return [];
          }
        }

        return [
          getAngleTableExportableItem({
            data: angleData,
            timezone
          })
        ];
      }
    };

    return layoutExportableOutcomes;
  };

export const selectDataForGeneralRecordingInfo =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    const { recordingParameters } = activeFile.datxContent;

    if (isNil(recordingParameters)) {
      return undefined;
    }

    return createVmGeneralRecordingInformationCard(recordingParameters);
  };

export const selectCurrentAndAvailableLayouts =
  (id: string) => (state: StoreApi) => {
    const currentDashboard = selectDashboardLayoutsById(id)(state);
    const availableLayouts = getAddableLayouts(currentDashboard.layouts);

    return { currentDashboard, availableLayouts };
  };

export const selectActiveDashboardKeyForDatx =
  (id: string) => (state: StoreApi) => {
    const activeFile = selectDatxByFileById(id)(state);

    return activeFile.activeDashboard;
  };

export const selectTimestampForRecStartFromActiveFile = (state: StoreApi) => {
  const { activeFileId, openFiles } = state.openDatx;

  if (isNil(activeFileId)) {
    return undefined;
  }

  const activeFile = openFiles[activeFileId];

  const timestampForRecStart = activeFile?.datxContent.data.find((data) => {
    return data.runningStatus?.some((status) => status === "recStart");
  })?.timestamp;

  if (isNil(timestampForRecStart)) {
    return undefined;
  }

  return timestampForRecStart;
};

export const selectTimestampForRecEndFromActiveFile = (state: StoreApi) => {
  const { activeFileId, openFiles } = state.openDatx;

  if (isNil(activeFileId)) {
    return undefined;
  }

  const activeFile = openFiles[activeFileId];

  const datapointForRecEnd = activeFile?.datxContent.data.find((data) => {
    return data.runningStatus?.some(
      (status) =>
        status === "recEndNormal" ||
        status === "recEndWriteError" ||
        status === "recEndMemoryFull"
    );
  });

  if (isNil(datapointForRecEnd)) {
    return undefined;
  }

  const recEnd = {
    timestamp: datapointForRecEnd.timestamp,
    status: datapointForRecEnd.runningStatus?.slice(-1)[0]
  };

  return recEnd;
};

//utility functions todo: maybe move this section to some other file

/**
 * Creates a Datx-Content object
 * @param unpackedData
 */
export const unpackedDataToDatxContent = (
  unpackedData: ParsedDatxWithHeader
): IDatxContent => {
  const { systemInfo, startup } = unpackedData;

  const recordingParameters = vmRecordingParametersFromPartial(
    unpackedData.recordingParameters
  );

  const data = createDatasetWithUniqueTimestamps(
    unpackedData.recordingData.values()
  );

  // FAT100 does not guarantee sorted data, therefore we need to sort it ourselves.
  data.sort((a, b) => a.timestamp - b.timestamp);

  const dvaData = dtDva2ViewModel(unpackedData.dvaData as any);

  return {
    header: unpackedData.header,
    recordingParameters,
    systemInfo,
    data,
    dvaData,
    startup
  };
};
